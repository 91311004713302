/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Complex: { input: unknown; output: unknown; }
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. Format is `yyyy-MM-dd` */
  DateOnly: { input: string; output: string; }
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: { input: string; output: string; }
  Decimal: { input: number; output: number; }
  Guid: { input: string; output: string; }
  Long: { input: number; output: number; }
  Uri: { input: string; output: string; }
};

export type ActivityLog = {
  changeDescription: Scalars['String']['output'];
  dateTime: Scalars['DateTimeOffset']['output'];
  id: Scalars['ID']['output'];
  rowId: Scalars['ID']['output'];
  tableName: Scalars['String']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

/** A connection from an object to a list of objects of type 'ActivityLog'. */
export type ActivityLogConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<ActivityLogEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<ActivityLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'ActivityLog'. */
export type ActivityLogEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ActivityLog;
};

export type ActivityLogV2 = {
  changeDescription: Scalars['String']['output'];
  changeMode: ChangeMode;
  dateTime: Scalars['DateTimeOffset']['output'];
  id: Scalars['Long']['output'];
  millisecondInQueue: Scalars['Float']['output'];
  rowId: Scalars['Long']['output'];
  tableName: Scalars['String']['output'];
  userId?: Maybe<Scalars['Long']['output']>;
};

export type Address = {
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Admin = {
  longRunningTask?: Maybe<LongRunningTask>;
  longRunningTasks: Array<LongRunningTask>;
  queueInfo: QueueInfo;
  /** Runs the warehouse consistency check and returns any error found. */
  runWarehouseConsistencyCheck: Array<WarehouseConsistencyCheckModelGraph>;
  serverInfo: ServerInfo;
};


export type AdminLongRunningTaskArgs = {
  id: Scalars['ID']['input'];
};

export type AdminMutation = {
  cancelLongRunningTask: Scalars['Boolean']['output'];
  repairWarehouseAvailability: Scalars['Boolean']['output'];
  resetDatabase: Scalars['Boolean']['output'];
  resetHubSpotContacts: Scalars['Boolean']['output'];
};


export type AdminMutationCancelLongRunningTaskArgs = {
  id: Scalars['ID']['input'];
};


export type AdminMutationResetDatabaseArgs = {
  areYouSure: Scalars['String']['input'];
};


export type AdminMutationResetHubSpotContactsArgs = {
  areYouSure: Scalars['String']['input'];
};

export type AmazonAuthGraph = {
  authorizationUrl: Scalars['Uri']['output'];
};


export type AmazonAuthGraphAuthorizationUrlArgs = {
  vendor: Scalars['Boolean']['input'];
};

export type AmazonAuthInput = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  merchantToken?: InputMaybe<Scalars['String']['input']>;
  oAuthUrl?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  roleArn?: InputMaybe<Scalars['String']['input']>;
  sQSArn?: InputMaybe<Scalars['String']['input']>;
  sQSFeedsArn?: InputMaybe<Scalars['String']['input']>;
  sQSFeedsUrl?: InputMaybe<Scalars['String']['input']>;
  sQSReportsArn?: InputMaybe<Scalars['String']['input']>;
  sQSReportsUrl?: InputMaybe<Scalars['String']['input']>;
  sQSUrl?: InputMaybe<Scalars['String']['input']>;
  sTSAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  sTSSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AmazonAuthMutation = {
  saveTokens: Scalars['Boolean']['output'];
};


export type AmazonAuthMutationSaveTokensArgs = {
  tokens: AmazonAuthInput;
};

export type AmazonFulfillmentCenter = {
  address: Address;
  addressId: Scalars['ID']['output'];
  cartonQuantitySAN: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partyId: Scalars['String']['output'];
  sAN: Scalars['String']['output'];
};

/** A connection from an object to a list of objects of type 'AmazonFulfillmentCenter'. */
export type AmazonFulfillmentCenterConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<AmazonFulfillmentCenterEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<AmazonFulfillmentCenter>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'AmazonFulfillmentCenter'. */
export type AmazonFulfillmentCenterEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: AmazonFulfillmentCenter;
};

export type AmazonTemplates = {
  amazonFile: Scalars['String']['output'];
  crossReferenceFile: Scalars['String']['output'];
};

export type ApiAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ApiLog = {
  api: Scalars['String']['output'];
  exception?: Maybe<Scalars['String']['output']>;
  executionTimeMs: Scalars['Int']['output'];
  extraData?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  method: Scalars['String']['output'];
  payload?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

/** A connection from an object to a list of objects of type 'ApiLog'. */
export type ApiLogConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<ApiLogEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<ApiLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'ApiLog'. */
export type ApiLogEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ApiLog;
};

export type ApiMutation = {
  importExport: ImportExportMutation;
  print: PrintApiMutation;
  product: ProductApiMutation;
  return: ReturnApiMutation;
  sale: SaleApiMutation;
  shippingLabel: ShippingLabelApiMutation;
};

export type ArchivedSaleFulfillmentInput = {
  hasInsurance: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  insuranceAmount?: InputMaybe<Scalars['Decimal']['input']>;
  labelAttachmentId?: InputMaybe<Scalars['ID']['input']>;
  labelProviderId?: InputMaybe<Scalars['ID']['input']>;
  labelProviderShipmentId?: InputMaybe<Scalars['String']['input']>;
  lineItems?: InputMaybe<Array<ArchivedSaleFulfillmentLineItemInput>>;
  packageId?: InputMaybe<Scalars['ID']['input']>;
  packageNumber: Scalars['Int']['input'];
  purchaseInvoiceId?: InputMaybe<Scalars['ID']['input']>;
  shipDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  shippingServiceId?: InputMaybe<Scalars['ID']['input']>;
  status: SaleFulfillmentStatus;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ArchivedSaleFulfillmentLineItemInput = {
  packageQuantity: Scalars['Int']['input'];
  productId?: InputMaybe<Scalars['ID']['input']>;
  saleLineItemId?: InputMaybe<Scalars['ID']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
};

export type ArchivedSaleInput = {
  archivedSaleFulfillments?: InputMaybe<Array<ArchivedSaleFulfillmentInput>>;
  billingAddress?: InputMaybe<AddressInput>;
  childSaleType?: InputMaybe<ChildSaleType>;
  customerNotes?: InputMaybe<Scalars['String']['input']>;
  customerReference?: InputMaybe<Scalars['String']['input']>;
  dateDelivered?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  dateShipped?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  dateVoided?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  dropShip: Scalars['Boolean']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  lineItems?: InputMaybe<Array<SaleLineItemInput>>;
  orderDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  paidSubtotal?: InputMaybe<Scalars['Decimal']['input']>;
  paidTax?: InputMaybe<Scalars['Decimal']['input']>;
  parentSaleId?: InputMaybe<Scalars['ID']['input']>;
  rootSaleId?: InputMaybe<Scalars['ID']['input']>;
  saleAttributes?: InputMaybe<Array<SaleAttributeInput>>;
  salesChannelId?: InputMaybe<Scalars['ID']['input']>;
  salesChannelReference?: InputMaybe<Scalars['String']['input']>;
  shipBy?: InputMaybe<Scalars['DateOnly']['input']>;
  shippingAddress?: InputMaybe<AddressInput>;
  shippingServiceId?: InputMaybe<Scalars['ID']['input']>;
  status: SaleStatus;
  subtotal: Scalars['Decimal']['input'];
  tax: Scalars['Decimal']['input'];
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
  total: Scalars['Decimal']['input'];
};

export type Attachment = {
  /** This returns the data as a base64 string; Url is preferred for out-of-band data retrieval */
  data: Scalars['String']['output'];
  dateUploaded: Scalars['DateTimeOffset']['output'];
  extension: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isLabel: Scalars['Boolean']['output'];
  labelProvider?: Maybe<LabelProvider>;
  labelProviderId?: Maybe<Scalars['ID']['output']>;
  labelProviderShipmentId?: Maybe<Scalars['String']['output']>;
  mimeType: Scalars['String']['output'];
  shippingService?: Maybe<ShippingService>;
  shippingServiceId?: Maybe<Scalars['ID']['output']>;
  size: Scalars['Long']['output'];
  sortOrder: Scalars['Float']['output'];
  trackingNumber?: Maybe<Scalars['String']['output']>;
  url: Scalars['Uri']['output'];
};

export type AttachmentsAuth = {
  accountName: Scalars['String']['output'];
  containerName: Scalars['String']['output'];
};

export type AttachmentsAuthMutation = {
  saveAzureTokens: Scalars['Boolean']['output'];
};


export type AttachmentsAuthMutationSaveAzureTokensArgs = {
  tokens?: InputMaybe<AzureKeysInput>;
};

export type AttachmentsMutation = {
  deleteAttachment: Scalars['Boolean']['output'];
  upload: Attachment;
  uploadAmazonFbaLabels: Array<Attachment>;
  uploadAmazonVendorLabels: Scalars['Boolean']['output'];
};


export type AttachmentsMutationDeleteAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  productId?: InputMaybe<Scalars['ID']['input']>;
  purchaseInvoiceId?: InputMaybe<Scalars['ID']['input']>;
  purchaseOrderId?: InputMaybe<Scalars['ID']['input']>;
  saleId?: InputMaybe<Scalars['ID']['input']>;
};


export type AttachmentsMutationUploadArgs = {
  data: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  isLabel?: Scalars['Boolean']['input'];
  mediaType: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['ID']['input']>;
  purchaseInvoiceId?: InputMaybe<Scalars['ID']['input']>;
  purchaseOrderId?: InputMaybe<Scalars['ID']['input']>;
  saleFulfillmentId?: InputMaybe<Scalars['ID']['input']>;
  saleId?: InputMaybe<Scalars['ID']['input']>;
};


export type AttachmentsMutationUploadAmazonFbaLabelsArgs = {
  data: Scalars['String']['input'];
  saleId: Scalars['ID']['input'];
  shippingServiceId: Scalars['ID']['input'];
};


export type AttachmentsMutationUploadAmazonVendorLabelsArgs = {
  cartonLabelData: Scalars['String']['input'];
  cartonSplitData: Scalars['String']['input'];
  saleId: Scalars['ID']['input'];
  shippingLabelData: Scalars['String']['input'];
  shippingServiceId: Scalars['ID']['input'];
};

export type AvailPackages = {
  package: Package;
  packageId: Scalars['ID']['output'];
  suggested: Scalars['Boolean']['output'];
};

export type AzureKeysInput = {
  accountName: Scalars['String']['input'];
  containerName: Scalars['String']['input'];
  sharedKey: Scalars['String']['input'];
};

export enum BarcodeLabelType {
  /** Code128 */
  Code_128 = 'CODE_128',
  /** QR */
  Qr = 'QR'
}

export type BarcodeSearchResult = {
  id: Scalars['ID']['output'];
  model: SearchResultUnion;
  type: ModelType;
};

export type BulkGenerateLabels = {
  errors?: Maybe<Array<Scalars['String']['output']>>;
  labels: Array<PrintDataMutation>;
};

export type BulkProductIncludedProductMappingInput = {
  includedSku: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type BulkSalesChannelListingInput = {
  listingSku: Scalars['String']['input'];
  productSku: Scalars['String']['input'];
  referenceNumber: Scalars['String']['input'];
  salesChannelName: Scalars['String']['input'];
  zbDbListingId: Scalars['String']['input'];
};

export enum ChangeMode {
  Add = 'ADD',
  Change = 'CHANGE',
  Delete = 'DELETE',
  Unknown = 'UNKNOWN'
}

export enum ChildSaleType {
  Incomplete = 'INCOMPLETE',
  Reship = 'RESHIP'
}

/** The type of the value in the total row */
export enum ColumnTotal {
  /** Computes the average value of the non-null column entries */
  Average = 'AVERAGE',
  /** Counts the non-null column entries */
  Count = 'COUNT',
  /** The sum of the values in the column */
  Sum = 'SUM',
  /** Prints the word 'Total' */
  Text = 'TEXT'
}

export type Country = {
  id: Scalars['ID']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
};

/** A connection from an object to a list of objects of type 'Country'. */
export type CountryConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<CountryEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Country>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Country'. */
export type CountryEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Country;
};

export type CreateReturnInput = {
  atFault: ReturnFault;
  attemptedMachineMake?: InputMaybe<Scalars['String']['input']>;
  attemptedMachineModel?: InputMaybe<Scalars['String']['input']>;
  conditionDetails?: InputMaybe<Scalars['String']['input']>;
  individualLabels: Scalars['Boolean']['input'];
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  lastShipmentMoveDate?: InputMaybe<Scalars['String']['input']>;
  originalSaleId: Scalars['ID']['input'];
  packageType?: InputMaybe<PackageType>;
  packingType?: InputMaybe<PackingType>;
  reshipShippingServiceId?: InputMaybe<Scalars['ID']['input']>;
  returnLineItemInputModels: Array<CreateReturnLineItemInput>;
  returnReasonId: Scalars['ID']['input'];
  returnSalesChannelReference?: InputMaybe<Scalars['String']['input']>;
  returnShippingServiceId?: InputMaybe<Scalars['ID']['input']>;
  shippingLabelCost?: InputMaybe<Scalars['Decimal']['input']>;
  shippingLabelSendTo?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReturnLineItemInput = {
  expectedCondition: ProductCondition;
  originalSaleLineItemId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  quantityReship: Scalars['Decimal']['input'];
  quantityReturn: Scalars['Decimal']['input'];
  refundAmount: Scalars['Decimal']['input'];
  refundExpected: Scalars['Boolean']['input'];
  returnTo: ReturnTo;
  sku?: InputMaybe<Scalars['String']['input']>;
};

export type DataSet = {
  /** Returns a single table as CSV */
  csv: Scalars['String']['output'];
  /** Returns each table in the data set as a separate CSV file stored within a ZIP file, encoded as a base64 string */
  csvZip: Scalars['String']['output'];
  /** Returns the data set as a JSON object */
  data: Scalars['Complex']['output'];
  /** Returns the data set as an Excel sheet, encoded as a base64 string */
  excel: Scalars['String']['output'];
  /** Returns the data set as a JSON string */
  json: Scalars['String']['output'];
  /** Returns the data set as XML */
  xml: Scalars['String']['output'];
  /** Returns the XML schema for the data set */
  xmlSchema: Scalars['String']['output'];
};

export type Device = {
  active: Scalars['Boolean']['output'];
  connectionGuid: Scalars['Guid']['output'];
  description: Scalars['String']['output'];
  deviceTypes: Array<DeviceType>;
  hiveLink?: Maybe<HiveLink>;
  hiveLinkId?: Maybe<Scalars['ID']['output']>;
  hostName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  paperHeight?: Maybe<Scalars['Decimal']['output']>;
  paperWidth?: Maybe<Scalars['Decimal']['output']>;
  port?: Maybe<Scalars['Int']['output']>;
  sortOrder: Scalars['Float']['output'];
  stationSet?: Maybe<StationSet>;
  stationSetId?: Maybe<Scalars['ID']['output']>;
  type: DeviceSubType;
};

/** A connection from an object to a list of objects of type 'Device'. */
export type DeviceConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<DeviceEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Device>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Device'. */
export type DeviceEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Device;
};

export type DeviceInput = {
  active: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  deviceTypes: Array<DeviceType>;
  hiveLinkId?: InputMaybe<Scalars['ID']['input']>;
  hostName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  paperHeight?: InputMaybe<Scalars['Decimal']['input']>;
  paperWidth?: InputMaybe<Scalars['Decimal']['input']>;
  port?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: Scalars['Float']['input'];
  stationSetId?: InputMaybe<Scalars['ID']['input']>;
  type: DeviceSubType;
};

export type DeviceMutation = {
  add: Device;
  delete: Scalars['ID']['output'];
  edit: Device;
  readDimensions: PackageDimensions;
  readWeight: Scalars['Float']['output'];
  /** Takes a picture and returns it in base-64 format */
  takePicture: Scalars['String']['output'];
};


export type DeviceMutationAddArgs = {
  value: DeviceInput;
};


export type DeviceMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type DeviceMutationEditArgs = {
  modified: DeviceInput;
  original: DeviceInput;
};


export type DeviceMutationReadDimensionsArgs = {
  id: Scalars['ID']['input'];
};


export type DeviceMutationReadWeightArgs = {
  id: Scalars['ID']['input'];
};


export type DeviceMutationTakePictureArgs = {
  id: Scalars['ID']['input'];
};

export enum DeviceSubType {
  /** Color */
  Color = 'COLOR',
  /** Generic */
  Generic = 'GENERIC',
  /** Label */
  Label = 'LABEL',
  /** Receipt */
  Receipt = 'RECEIPT',
  /** Rice Lake QubeVu */
  RiceLakeQubeVu = 'RICE_LAKE_QUBE_VU'
}

export enum DeviceType {
  BarcodeScanner = 'BARCODE_SCANNER',
  Camera = 'CAMERA',
  Dimensioner = 'DIMENSIONER',
  Printer = 'PRINTER',
  Scale = 'SCALE'
}

export type DiceStatus = {
  description: Scalars['String']['output'];
};

export type DisplayPicksFiltersInput = {
  saleIds: Array<Scalars['ID']['input']>;
  salesChannels: Array<Scalars['ID']['input']>;
  shipByToday: Scalars['Boolean']['input'];
};

export type DisplayPicksInput = {
  aisle: Scalars['String']['input'];
  pickEquipmentId: Scalars['ID']['input'];
};

export type DropShipExportOutput = {
  fulfillableOrdersCsv: Scalars['String']['output'];
  unfulfillableExcelString: Scalars['String']['output'];
};

export enum DropShipMode {
  Always = 'ALWAYS',
  Never = 'NEVER',
  Optional = 'OPTIONAL'
}

export type EbayAuthGraph = {
  authorizationUrl: Scalars['Uri']['output'];
};

export type EbayAuthInput = {
  /** The current eBay access token for REST APIs */
  accessToken?: InputMaybe<Scalars['String']['input']>;
  /** The datetimeoffset that the access token expires */
  accessTokenExpires?: InputMaybe<Scalars['String']['input']>;
  /** The eBay AppId secret */
  appId?: InputMaybe<Scalars['String']['input']>;
  /** The eBay AuthenticationToken for the Trading API */
  authenticationToken?: InputMaybe<Scalars['String']['input']>;
  /** The eBay OAuth authorization URL */
  authorizeUrl?: InputMaybe<Scalars['String']['input']>;
  /** The eBay CertId secret */
  certId?: InputMaybe<Scalars['String']['input']>;
  /** The eBay DevId secret */
  devId?: InputMaybe<Scalars['String']['input']>;
  /** The eBay OAuth URL */
  oAuthUrl?: InputMaybe<Scalars['String']['input']>;
  /** The ebay RedirectUri secret (not an actual Uri) */
  redirectUri?: InputMaybe<Scalars['String']['input']>;
  /** The current refresh token for eBay REST APIs */
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  /** The list of eBay API scopes to request */
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EbayAuthMutation = {
  saveTokens: Scalars['Boolean']['output'];
};


export type EbayAuthMutationSaveTokensArgs = {
  model: EbayAuthInput;
};

export type ErrorRecord = {
  entityId?: Maybe<Scalars['String']['output']>;
  errorMessage: Scalars['String']['output'];
  errorType: Scalars['String']['output'];
};

export type ExportMutation = {
  amazonOrdersTemplates: AmazonTemplates;
  dropShipOrders: DropShipExportOutput;
  exportProductLocationSearchedSelected: Scalars['String']['output'];
  locationBarcodes: Scalars['String']['output'];
  monthlySales: Scalars['String']['output'];
  productAvailabilities: Scalars['String']['output'];
  productDetails: Scalars['String']['output'];
  purchaseInvoiceLineItems: Scalars['String']['output'];
  purchaseOrderLineItems: Scalars['String']['output'];
  report: DataSet;
  restockReport: Scalars['String']['output'];
  restockReportHistory: Scalars['String']['output'];
  saleLineItems: Scalars['String']['output'];
  userScoresReport: Scalars['String']['output'];
};


export type ExportMutationDropShipOrdersArgs = {
  purchaseOrderId?: InputMaybe<Scalars['ID']['input']>;
  supplierId: Scalars['ID']['input'];
};


export type ExportMutationExportProductLocationSearchedSelectedArgs = {
  endDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
};


export type ExportMutationMonthlySalesArgs = {
  year: Scalars['Int']['input'];
};


export type ExportMutationPurchaseInvoiceLineItemsArgs = {
  purchaseInvoiceId: Scalars['ID']['input'];
};


export type ExportMutationPurchaseOrderLineItemsArgs = {
  purchaseOrderId: Scalars['ID']['input'];
};


export type ExportMutationReportArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<Scalars['Complex']['input']>;
};


export type ExportMutationRestockReportArgs = {
  baseDaysLeadTime?: InputMaybe<Scalars['Int']['input']>;
  daysToProject: Scalars['Int']['input'];
};


export type ExportMutationRestockReportHistoryArgs = {
  baseDaysLeadTime?: InputMaybe<Scalars['Int']['input']>;
  daysToProject: Scalars['Int']['input'];
};


export type ExportMutationSaleLineItemsArgs = {
  saleId: Scalars['ID']['input'];
};


export type ExportMutationUserScoresReportArgs = {
  endDate: Scalars['DateOnly']['input'];
  startDate: Scalars['DateOnly']['input'];
};

export type GraphQlAuth = {
  authorizationUrl?: Maybe<Scalars['Uri']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  graphQlUrl?: Maybe<Scalars['Uri']['output']>;
  hasClientSecret: Scalars['Boolean']['output'];
  hasRefreshToken: Scalars['Boolean']['output'];
};

export type GraphQlAuthInput = {
  authorizationUrl?: InputMaybe<Scalars['Uri']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  graphQlUrl?: InputMaybe<Scalars['Uri']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  setClientSecret: Scalars['Boolean']['input'];
  setRefreshToken: Scalars['Boolean']['input'];
};

export type GraphQlAuthMutation = {
  saveTokens: Scalars['Boolean']['output'];
};


export type GraphQlAuthMutationSaveTokensArgs = {
  tokens: GraphQlAuthInput;
};

export type HiveLink = {
  active: Scalars['Boolean']['output'];
  devices: Array<Device>;
  guid: Scalars['Guid']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  printers: Array<Device>;
};

/** A connection from an object to a list of objects of type 'HiveLink'. */
export type HiveLinkConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<HiveLinkEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<HiveLink>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'HiveLink'. */
export type HiveLinkEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: HiveLink;
};

export type HiveLinkInput = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type HiveLinkMutation = {
  add: HiveLink;
  delete: Scalars['ID']['output'];
  edit: HiveLink;
};


export type HiveLinkMutationAddArgs = {
  value: HiveLinkInput;
};


export type HiveLinkMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type HiveLinkMutationEditArgs = {
  modified: HiveLinkInput;
  original: HiveLinkInput;
};

export type ImportExportMutation = {
  export: ExportMutation;
  import: ImportMutation;
};

export type ImportMutation = {
  bulkPurchaseOrderStatusChangeToOrdered: Scalars['Boolean']['output'];
  importAmazonFbaOrders: LongRunningTask;
  importAmazonFbaStock: Scalars['Boolean']['output'];
  importAmazonFulfillmentCenters: Scalars['Boolean']['output'];
  importAmazonOrders: LongRunningTask;
  importBulkUpdateProducts: Scalars['Boolean']['output'];
  importCountries: Scalars['Boolean']['output'];
  importProductBarcodes: Scalars['Boolean']['output'];
  importSalesChannelPrice: Scalars['String']['output'];
  importSalesChannelReturnReasons: Scalars['Boolean']['output'];
  importSupplierStock: Scalars['Boolean']['output'];
  importZipCodeZones: Scalars['Boolean']['output'];
  purchaseInvoiceLineItems: PurchaseInvoice;
  purchaseOrderLineItems: PurchaseOrder;
  saleLineItems: Sale;
};


export type ImportMutationBulkPurchaseOrderStatusChangeToOrderedArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportAmazonFbaOrdersArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportAmazonFbaStockArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportAmazonFulfillmentCentersArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportAmazonOrdersArgs = {
  amazonExportFileBase64: Scalars['String']['input'];
  amazonXrefFileBase64: Scalars['String']['input'];
};


export type ImportMutationImportBulkUpdateProductsArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportCountriesArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportProductBarcodesArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportSalesChannelPriceArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportSalesChannelReturnReasonsArgs = {
  base64: Scalars['String']['input'];
  salesChannelId: Scalars['ID']['input'];
};


export type ImportMutationImportSupplierStockArgs = {
  base64: Scalars['String']['input'];
};


export type ImportMutationImportZipCodeZonesArgs = {
  base64: Scalars['String']['input'];
  shippingServiceId: Scalars['ID']['input'];
};


export type ImportMutationPurchaseInvoiceLineItemsArgs = {
  base64: Scalars['String']['input'];
  purchaseInvoiceId: Scalars['ID']['input'];
};


export type ImportMutationPurchaseOrderLineItemsArgs = {
  base64: Scalars['String']['input'];
  purchaseOrderId: Scalars['ID']['input'];
};


export type ImportMutationSaleLineItemsArgs = {
  base64: Scalars['String']['input'];
  saleId: Scalars['ID']['input'];
};

export type IntegrationsGraph = {
  amazon?: Maybe<AmazonAuthGraph>;
  attachments?: Maybe<AttachmentsAuth>;
  ebay?: Maybe<EbayAuthGraph>;
  zbDb: ZbDbIntegration;
  zbox: ZboxIntegration;
};

export type IntegrationsMutation = {
  amazonSeller: AmazonAuthMutation;
  amazonVendor: AmazonAuthMutation;
  attachments: AttachmentsAuthMutation;
  ebay: EbayAuthMutation;
  salesDb: GraphQlAuthMutation;
  zbDb: GraphQlAuthMutation;
  zbox: GraphQlAuthMutation;
};

export type InterchangeMatch = {
  matchedInterchanges: Array<Scalars['String']['output']>;
  productId: Scalars['ID']['output'];
  sku: Scalars['String']['output'];
};

export type InventoryMutation = {
  attachPackageToNewSaleFulfillment: SaleFulfillment;
  binAdjustment: Scalars['Decimal']['output'];
  bulkUpdateAllProductStockForIntegrations: Scalars['Boolean']['output'];
  moveFromPurchaseInvoice: Scalars['String']['output'];
  moveProductToBin: Scalars['String']['output'];
  moveProductsToBin: Scalars['String']['output'];
  moveToNewSaleFulfillment: SaleFulfillment;
  moveToSaleFulfillment: Scalars['String']['output'];
  stockTake: Scalars['String']['output'];
};


export type InventoryMutationAttachPackageToNewSaleFulfillmentArgs = {
  locationId: Scalars['ID']['input'];
  locationSelectionType?: SelectionType;
  packageId: Scalars['ID']['input'];
  packageSelectionType?: SelectionType;
  saleId: Scalars['ID']['input'];
};


export type InventoryMutationBinAdjustmentArgs = {
  locationId: Scalars['ID']['input'];
  locationSelectionType?: SelectionType;
  productId: Scalars['ID']['input'];
  productSelectionType?: SelectionType;
  quantity: Scalars['Decimal']['input'];
};


export type InventoryMutationBulkUpdateAllProductStockForIntegrationsArgs = {
  processAllProducts?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Array<Scalars['Long']['input']>>;
  skus?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type InventoryMutationMoveFromPurchaseInvoiceArgs = {
  locationId: Scalars['ID']['input'];
  locationSelectionType?: SelectionType;
  productId: Scalars['ID']['input'];
  productSelectionType?: SelectionType;
  purchaseInvoiceId: Scalars['ID']['input'];
  quantity: Scalars['Decimal']['input'];
};


export type InventoryMutationMoveProductToBinArgs = {
  fromLocationId: Scalars['ID']['input'];
  fromLocationSelectionType?: SelectionType;
  productId: Scalars['ID']['input'];
  productSelectionType?: SelectionType;
  quantity: Scalars['Decimal']['input'];
  saleId?: InputMaybe<Scalars['ID']['input']>;
  toLocationId: Scalars['ID']['input'];
  toLocationSelectionType?: SelectionType;
};


export type InventoryMutationMoveProductsToBinArgs = {
  moveProductsToBin: Array<MoveProductToBin>;
};


export type InventoryMutationMoveToNewSaleFulfillmentArgs = {
  locationId: Scalars['ID']['input'];
  locationSelectionType?: SelectionType;
  productId: Scalars['ID']['input'];
  productSelectionType?: SelectionType;
  quantity: Scalars['Decimal']['input'];
  saleId: Scalars['ID']['input'];
};


export type InventoryMutationMoveToSaleFulfillmentArgs = {
  locationId: Scalars['ID']['input'];
  locationSelectionType?: SelectionType;
  productId: Scalars['ID']['input'];
  productSelectionType?: SelectionType;
  quantity: Scalars['Decimal']['input'];
  saleFulfillmentId: Scalars['ID']['input'];
};


export type InventoryMutationStockTakeArgs = {
  locationId: Scalars['ID']['input'];
  locationSelectionType?: SelectionType;
  productId: Scalars['ID']['input'];
  productSelectionType?: SelectionType;
  quantity: Scalars['Decimal']['input'];
};

export type LabelProvider = {
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  services: Array<LabelProviderShippingServiceMapping>;
};

/** A connection from an object to a list of objects of type 'LabelProvider'. */
export type LabelProviderConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<LabelProviderEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<LabelProvider>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'LabelProvider'. */
export type LabelProviderEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: LabelProvider;
};

export type LabelProviderInput = {
  id: Scalars['ID']['input'];
  isDefault?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  services?: InputMaybe<Array<LabelProviderServiceMappingInput>>;
};

export type LabelProviderMutation = {
  add: LabelProvider;
  delete: Scalars['ID']['output'];
  edit: LabelProvider;
};


export type LabelProviderMutationAddArgs = {
  value: LabelProviderInput;
};


export type LabelProviderMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type LabelProviderMutationEditArgs = {
  modified: LabelProviderInput;
  original: LabelProviderInput;
};

export type LabelProviderServiceMappingInput = {
  id: Scalars['ID']['input'];
  labelProviderCarrierCode: Scalars['String']['input'];
  labelProviderServiceCode: Scalars['String']['input'];
  packageType?: InputMaybe<Scalars['String']['input']>;
  recordAsShippingServiceId: Scalars['ID']['input'];
  shippingServiceId: Scalars['ID']['input'];
  weightLimit?: InputMaybe<Scalars['Decimal']['input']>;
};

export type LabelProviderShippingServiceMapping = {
  id: Scalars['ID']['output'];
  labelProvider: LabelProvider;
  labelProviderCarrierCode: Scalars['String']['output'];
  labelProviderId: Scalars['ID']['output'];
  labelProviderServiceCode: Scalars['String']['output'];
  packageType?: Maybe<Scalars['String']['output']>;
  recordAsShippingServiceId: Scalars['ID']['output'];
  shippingService: ShippingService;
  shippingServiceId: Scalars['ID']['output'];
  weightLimit?: Maybe<Scalars['Decimal']['output']>;
};

export type LineItemTotalsModel = {
  quantity: Scalars['Decimal']['output'];
  quantityBackordered: Scalars['Decimal']['output'];
  quantityPicked: Scalars['Decimal']['output'];
  quantityPreviouslyShipped: Scalars['Decimal']['output'];
  quantityShipped: Scalars['Decimal']['output'];
  quantityShippedSeparately: Scalars['Decimal']['output'];
};

export type Location = {
  active: Scalars['Boolean']['output'];
  aisle?: Maybe<Scalars['String']['output']>;
  barcode?: Maybe<Scalars['String']['output']>;
  bin?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pickZone: PickZone;
  pickZoneId: Scalars['ID']['output'];
  productCount: Scalars['Decimal']['output'];
  products: Array<LocationProductMapping>;
  rack?: Maybe<Scalars['String']['output']>;
  shelf?: Maybe<Scalars['String']['output']>;
  side?: Maybe<Scalars['String']['output']>;
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
  zboxBarcode: Scalars['String']['output'];
};

/** A connection from an object to a list of objects of type 'Location'. */
export type LocationConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<LocationEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Location>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Location'. */
export type LocationEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Location;
};

export type LocationHistory = {
  completedByUser?: Maybe<User>;
  completedByUserId: Scalars['ID']['output'];
  date: Scalars['DateTimeOffset']['output'];
  id: Scalars['ID']['output'];
  location: Location;
  locationId: Scalars['ID']['output'];
  newQuantity: Scalars['Decimal']['output'];
  oldQuantity: Scalars['Decimal']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  referenceId?: Maybe<Scalars['ID']['output']>;
  referenceLocation?: Maybe<Location>;
  referencePurchaseInvoice?: Maybe<PurchaseInvoice>;
  referenceSaleFulfillment?: Maybe<SaleFulfillment>;
  type: LocationHistoryType;
};

export type LocationHistory2 = {
  completedByUserId: Scalars['ID']['output'];
  date: Scalars['DateTimeOffset']['output'];
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  newQuantity: Scalars['Decimal']['output'];
  oldQuantity: Scalars['Decimal']['output'];
  productId: Scalars['ID']['output'];
  referenceId?: Maybe<Scalars['ID']['output']>;
  type: LocationHistoryType;
};

/** A connection from an object to a list of objects of type 'LocationHistory'. */
export type LocationHistoryConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<LocationHistoryEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<LocationHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'LocationHistory'. */
export type LocationHistoryEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: LocationHistory;
};

export enum LocationHistoryType {
  BinAdjustment = 'BIN_ADJUSTMENT',
  MoveFromPurchaseInvoice = 'MOVE_FROM_PURCHASE_INVOICE',
  MoveProductToBin = 'MOVE_PRODUCT_TO_BIN',
  MoveToSaleFulfillment = 'MOVE_TO_SALE_FULFILLMENT',
  StockTake = 'STOCK_TAKE'
}

export type LocationInput = {
  active: Scalars['Boolean']['input'];
  aisle?: InputMaybe<Scalars['String']['input']>;
  bin?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  pickZoneId: Scalars['ID']['input'];
  rack?: InputMaybe<Scalars['String']['input']>;
  shelf?: InputMaybe<Scalars['String']['input']>;
  side?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['ID']['input'];
};

export type LocationMutation = {
  add: Array<Location>;
  bulkDelete: Scalars['String']['output'];
  delete: Scalars['ID']['output'];
  edit: Location;
  editPickZones: Array<Location>;
};


export type LocationMutationAddArgs = {
  value: LocationInput;
};


export type LocationMutationBulkDeleteArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type LocationMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type LocationMutationEditArgs = {
  modified: LocationInput;
  original: LocationInput;
};


export type LocationMutationEditPickZonesArgs = {
  aisle?: InputMaybe<Scalars['String']['input']>;
  bin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  newPickZoneId: Scalars['ID']['input'];
  pickZoneId?: InputMaybe<Scalars['ID']['input']>;
  pickable?: InputMaybe<Scalars['Boolean']['input']>;
  rack?: InputMaybe<Scalars['String']['input']>;
  shelf?: InputMaybe<Scalars['String']['input']>;
  side?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['ID']['input'];
};

export type LocationProductMapping = {
  location: Location;
  locationId: Scalars['ID']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  sale?: Maybe<Sale>;
  saleId?: Maybe<Scalars['ID']['output']>;
  stockOnHand: Scalars['Decimal']['output'];
};

export type LocationStock = {
  location: Location;
  locationId: Scalars['ID']['output'];
  stockOnHand: Scalars['Decimal']['output'];
};

export enum LocationsSortOrder {
  AisleAscending = 'AISLE_ASCENDING',
  AisleDescending = 'AISLE_DESCENDING',
  BinAscending = 'BIN_ASCENDING',
  BinDescending = 'BIN_DESCENDING',
  IdAscending = 'ID_ASCENDING',
  IdDescending = 'ID_DESCENDING',
  NameAscending = 'NAME_ASCENDING',
  NameDescending = 'NAME_DESCENDING',
  PickableAscending = 'PICKABLE_ASCENDING',
  PickableDescending = 'PICKABLE_DESCENDING',
  PickZoneAscending = 'PICK_ZONE_ASCENDING',
  PickZoneDescending = 'PICK_ZONE_DESCENDING',
  ProductCountAscending = 'PRODUCT_COUNT_ASCENDING',
  ProductCountDescending = 'PRODUCT_COUNT_DESCENDING',
  RackAscending = 'RACK_ASCENDING',
  RackDescending = 'RACK_DESCENDING',
  ShelfAscending = 'SHELF_ASCENDING',
  ShelfDescending = 'SHELF_DESCENDING',
  SideAscending = 'SIDE_ASCENDING',
  SideDescending = 'SIDE_DESCENDING'
}

export type Log = {
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTimeOffset']['output'];
  detailsJson?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['ID']['output']>;
  eventSource?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logLevel: LogLevel;
  message: Scalars['String']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

/** A connection from an object to a list of objects of type 'Log'. */
export type LogConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<LogEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Log>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Log'. */
export type LogEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Log;
};

export enum LogLevel {
  Critical = 'CRITICAL',
  Debug = 'DEBUG',
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export type LogMutation = {
  clearAllActive: Scalars['Boolean']['output'];
  setActive: Scalars['Boolean']['output'];
};


export type LogMutationSetActiveArgs = {
  ids: Array<Scalars['ID']['input']>;
  value?: Scalars['Boolean']['input'];
};

export type LongRunningTask = {
  complete: Scalars['Boolean']['output'];
  completedAt?: Maybe<Scalars['DateTimeOffset']['output']>;
  exception?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  log?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  percentComplete?: Maybe<Scalars['Float']['output']>;
  startedAt: Scalars['DateTimeOffset']['output'];
  status?: Maybe<Scalars['String']['output']>;
  taskStatus?: Maybe<TaskStatus>;
};

export type MobileQuery = {
  displayAreasToPick: Array<PickArea>;
  displayPicks: Array<Pick>;
  findEmptyCart: Array<Station>;
  findStations: Array<Station>;
  generateNumbersFromRanges: Array<Scalars['String']['output']>;
  getLocationProductsByUserQuery: Array<LocationProductMapping>;
  getOpenSaleFulfillmentState: OpenSaleFulfillmentState;
  getPackState: PackState;
  getWarehousePicks: Array<PickArea>;
  searchZbDbByInterchange: Array<InterchangeMatch>;
};


export type MobileQueryDisplayAreasToPickArgs = {
  displayPicksFiltersInputModel: DisplayPicksFiltersInput;
  warehouseId: Scalars['ID']['input'];
};


export type MobileQueryDisplayPicksArgs = {
  displayPicks: Array<DisplayPicksInput>;
  displayPicksFiltersInputModel: DisplayPicksFiltersInput;
  warehouseId: Scalars['ID']['input'];
};


export type MobileQueryFindEmptyCartArgs = {
  warehouseId: Scalars['ID']['input'];
};


export type MobileQueryFindStationsArgs = {
  stationSetType: StationSetType;
  stationType: StationType;
  warehouseId: Scalars['ID']['input'];
};


export type MobileQueryGenerateNumbersFromRangesArgs = {
  ranges: Array<Scalars['String']['input']>;
};


export type MobileQueryGetLocationProductsByUserQueryArgs = {
  userQueryFilters: Array<UserQueryFilter>;
  warehouseId: Scalars['ID']['input'];
};


export type MobileQueryGetOpenSaleFulfillmentStateArgs = {
  saleFulfillmentId: Scalars['ID']['input'];
  warehouseId: Scalars['ID']['input'];
};


export type MobileQueryGetPackStateArgs = {
  productId?: InputMaybe<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
  stationId: Scalars['ID']['input'];
  warehouseId: Scalars['ID']['input'];
};


export type MobileQueryGetWarehousePicksArgs = {
  warehouseId: Scalars['ID']['input'];
};


export type MobileQuerySearchZbDbByInterchangeArgs = {
  interchangePartNumber: Scalars['String']['input'];
};

export enum ModelType {
  Location = 'LOCATION',
  PickZone = 'PICK_ZONE',
  Product = 'PRODUCT',
  PurchaseInvoice = 'PURCHASE_INVOICE',
  PurchaseOrder = 'PURCHASE_ORDER',
  Return = 'RETURN',
  SaleFulfillment = 'SALE_FULFILLMENT',
  SaleOrder = 'SALE_ORDER',
  StationSet = 'STATION_SET',
  Supplier = 'SUPPLIER',
  Warehouse = 'WAREHOUSE'
}

export type MoveProductToBin = {
  fromLocationId: Scalars['ID']['input'];
  fromLocationSelectionType?: SelectionType;
  productId: Scalars['ID']['input'];
  productSelectionType?: SelectionType;
  quantity: Scalars['Decimal']['input'];
  saleId?: InputMaybe<Scalars['ID']['input']>;
  toLocationId: Scalars['ID']['input'];
  toLocationSelectionType?: SelectionType;
};

export type Mutation = {
  admin: AdminMutation;
  api: ApiMutation;
  attachments: AttachmentsMutation;
  device: DeviceMutation;
  hiveLink: HiveLinkMutation;
  integrations: IntegrationsMutation;
  inventory: InventoryMutation;
  labelProvider: LabelProviderMutation;
  location: LocationMutation;
  log: LogMutation;
  packFilter: PackFilterMutation;
  package: PackageMutation;
  paymentTerm: PaymentTermMutation;
  pickEquipment: PickEquipmentMutation;
  pickFilter: PickFilterMutation;
  pickZone: PickZoneMutation;
  product: ProductMutation;
  purchaseInvoice: PurchaseInvoiceMutation;
  purchaseOrder: PurchaseOrderMutation;
  query: Query;
  report: ReportMutation;
  return: ReturnMutation;
  returnReason: ReturnReasonMutation;
  sale: SaleMutation;
  salesChannel: SalesChannelMutation;
  setting: SettingMutation;
  shippingCarrier: ShippingCarrierMutation;
  stationSet: StationSetMutation;
  supplier: SupplierMutation;
  user: UserMutation;
  warehouse: WarehouseMutation;
};

export type OpenSaleFulfillmentState = {
  doItemsRemain: Scalars['Boolean']['output'];
  saleId: Scalars['ID']['output'];
  stationId?: Maybe<Scalars['ID']['output']>;
};

export type PackFilter = {
  heightMax: Scalars['Decimal']['output'];
  heightMin: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  lengthMax: Scalars['Decimal']['output'];
  lengthMin: Scalars['Decimal']['output'];
  package: Scalars['String']['output'];
  prepack?: Maybe<Scalars['Boolean']['output']>;
  size: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
  weightMax: Scalars['Decimal']['output'];
  weightMin: Scalars['Decimal']['output'];
  widthMax: Scalars['Decimal']['output'];
  widthMin: Scalars['Decimal']['output'];
};

/** A connection from an object to a list of objects of type 'PackFilter'. */
export type PackFilterConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<PackFilterEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<PackFilter>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'PackFilter'. */
export type PackFilterEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: PackFilter;
};

export type PackFilterInput = {
  heightMax: Scalars['Decimal']['input'];
  heightMin: Scalars['Decimal']['input'];
  id: Scalars['ID']['input'];
  lengthMax: Scalars['Decimal']['input'];
  lengthMin: Scalars['Decimal']['input'];
  package: Scalars['String']['input'];
  prepack?: InputMaybe<Scalars['Boolean']['input']>;
  size: Scalars['String']['input'];
  sortOrder: Scalars['Float']['input'];
  weightMax: Scalars['Decimal']['input'];
  weightMin: Scalars['Decimal']['input'];
  widthMax: Scalars['Decimal']['input'];
  widthMin: Scalars['Decimal']['input'];
};

export type PackFilterMutation = {
  add: PackFilter;
  delete: Scalars['ID']['output'];
  edit: PackFilter;
};


export type PackFilterMutationAddArgs = {
  value: PackFilterInput;
};


export type PackFilterMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PackFilterMutationEditArgs = {
  modified: PackFilterInput;
  original: PackFilterInput;
};

export type PackState = {
  availablePackages: Array<AvailPackages>;
  isOrderPackable: Scalars['Boolean']['output'];
  requiredProducts: Array<RequiredProduct>;
};

export type Package = {
  active: Scalars['Boolean']['output'];
  barcode?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  height: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  length: Scalars['Decimal']['output'];
  packageProducts: Array<Product>;
  type: PackageType;
  weight: Scalars['Decimal']['output'];
  width: Scalars['Decimal']['output'];
};

/** A connection from an object to a list of objects of type 'Package'. */
export type PackageConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<PackageEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Package>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

export type PackageDimensions = {
  height: Scalars['Float']['output'];
  length: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

/** An edge in a connection from an object to another object of type 'Package'. */
export type PackageEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Package;
};

export type PackageInput = {
  active: Scalars['Boolean']['input'];
  barcode?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Decimal']['input'];
  id: Scalars['ID']['input'];
  length: Scalars['Decimal']['input'];
  packageProductSkus?: InputMaybe<Array<Scalars['String']['input']>>;
  type: PackageType;
  weight: Scalars['Decimal']['input'];
  width: Scalars['Decimal']['input'];
};

export type PackageMutation = {
  add: Package;
  delete: Scalars['ID']['output'];
  edit: Package;
};


export type PackageMutationAddArgs = {
  value: PackageInput;
};


export type PackageMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PackageMutationEditArgs = {
  modified: PackageInput;
  original: PackageInput;
};

export enum PackageType {
  Box = 'BOX',
  Mailer = 'MAILER',
  PaddedMailer = 'PADDED_MAILER',
  Pallet = 'PALLET',
  Prepackaged = 'PREPACKAGED',
  Tube = 'TUBE'
}

export enum PackingType {
  AirBubbles = 'AIR_BUBBLES',
  Nothing = 'NOTHING',
  Paper = 'PAPER',
  Peanuts = 'PEANUTS'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaymentTerm = {
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
};

/** A connection from an object to a list of objects of type 'PaymentTerm'. */
export type PaymentTermConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<PaymentTermEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<PaymentTerm>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'PaymentTerm'. */
export type PaymentTermEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: PaymentTerm;
};

export type PaymentTermInput = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  sortOrder: Scalars['Float']['input'];
};

export type PaymentTermMutation = {
  add: PaymentTerm;
  delete: Scalars['ID']['output'];
  edit: PaymentTerm;
};


export type PaymentTermMutationAddArgs = {
  value: PaymentTermInput;
};


export type PaymentTermMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PaymentTermMutationEditArgs = {
  modified: PaymentTermInput;
  original: PaymentTermInput;
};

export type Pick = {
  location: Location;
  locationId: Scalars['ID']['output'];
  pickSaleModels: Array<PickSale>;
  product: Product;
  productId: Scalars['ID']['output'];
};

export type PickArea = {
  aisle?: Maybe<Scalars['String']['output']>;
  equipment: PickEquipment2;
  otherCartInAisle: Scalars['Boolean']['output'];
  quantity: Scalars['Decimal']['output'];
};

export type PickEquipment = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PickEquipment2 = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** A connection from an object to a list of objects of type 'PickEquipment'. */
export type PickEquipmentConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<PickEquipmentEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<PickEquipment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'PickEquipment'. */
export type PickEquipmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: PickEquipment;
};

export type PickEquipmentInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type PickEquipmentMutation = {
  add: PickEquipment;
  delete: Scalars['ID']['output'];
  edit: PickEquipment;
};


export type PickEquipmentMutationAddArgs = {
  value: PickEquipmentInput;
};


export type PickEquipmentMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PickEquipmentMutationEditArgs = {
  modified: PickEquipmentInput;
  original: PickEquipmentInput;
};

export type PickFilter = {
  heightMax: Scalars['Decimal']['output'];
  heightMin: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  lengthMax: Scalars['Decimal']['output'];
  lengthMin: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  prepack?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  weightMax: Scalars['Decimal']['output'];
  weightMin: Scalars['Decimal']['output'];
  widthMax: Scalars['Decimal']['output'];
  widthMin: Scalars['Decimal']['output'];
};

/** A connection from an object to a list of objects of type 'PickFilter'. */
export type PickFilterConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<PickFilterEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<PickFilter>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'PickFilter'. */
export type PickFilterEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: PickFilter;
};

export type PickFilterInput = {
  heightMax: Scalars['Decimal']['input'];
  heightMin: Scalars['Decimal']['input'];
  id: Scalars['ID']['input'];
  lengthMax: Scalars['Decimal']['input'];
  lengthMin: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  prepack?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder: Scalars['Float']['input'];
  weightMax: Scalars['Decimal']['input'];
  weightMin: Scalars['Decimal']['input'];
  widthMax: Scalars['Decimal']['input'];
  widthMin: Scalars['Decimal']['input'];
};

export type PickFilterMutation = {
  add: PickFilter;
  delete: Scalars['ID']['output'];
  edit: PickFilter;
};


export type PickFilterMutationAddArgs = {
  value: PickFilterInput;
};


export type PickFilterMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PickFilterMutationEditArgs = {
  modified: PickFilterInput;
  original: PickFilterInput;
};

export type PickFilterPickZoneMapping = {
  pickEquipmentId: Scalars['ID']['output'];
  pickFilterId: Scalars['ID']['output'];
  pickZoneId: Scalars['ID']['output'];
};

/** A connection from an object to a list of objects of type 'PickFilterPickZoneMapping'. */
export type PickFilterPickZoneMappingConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<PickFilterPickZoneMappingEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<PickFilterPickZoneMapping>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'PickFilterPickZoneMapping'. */
export type PickFilterPickZoneMappingEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: PickFilterPickZoneMapping;
};

export type PickFilterPickZoneMappingInput = {
  pickEquipmentId: Scalars['ID']['input'];
  pickFilterId: Scalars['ID']['input'];
};

export type PickSale = {
  quantityToPick: Scalars['Decimal']['output'];
  sale: Sale;
  saleId: Scalars['ID']['output'];
};

export type PickZone = {
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isCart: Scalars['Boolean']['output'];
  /** For a cart, this indicates the current location of the cart (the last bin that this cart was near); this is always null for any other type of pick zone.  This may be null if the cart was recently moved to a new station. */
  lastLocation?: Maybe<Location>;
  /** For a cart, this indicates the current location of the cart (the last bin that this cart was near); this is always null for any other type of pick zone.  This may be null if the cart was recently moved to a new station. */
  lastLocationId?: Maybe<Scalars['ID']['output']>;
  locations: Array<Location>;
  name: Scalars['String']['output'];
  pickFilters: Array<PickFilterPickZoneMapping>;
  pickable: Scalars['Boolean']['output'];
  priority: Scalars['Float']['output'];
  /** For a cart, this indicates the current location of the cart (the station that the cart was last left at); this is always null for any other type of pick zone. */
  station?: Maybe<Station>;
  /** For a cart, this indicates the current location of the cart (the station that the cart was last left at); this is always null for any other type of pick zone. */
  stationId?: Maybe<Scalars['ID']['output']>;
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
  zboxBarcode: Scalars['String']['output'];
};

/** A connection from an object to a list of objects of type 'PickZone'. */
export type PickZoneConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<PickZoneEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<PickZone>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'PickZone'. */
export type PickZoneEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: PickZone;
};

export type PickZoneInput = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isCart: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  pickFilters?: InputMaybe<Array<PickFilterPickZoneMappingInput>>;
  pickable: Scalars['Boolean']['input'];
  priority: Scalars['Float']['input'];
  stationId?: InputMaybe<Scalars['ID']['input']>;
  warehouseId: Scalars['ID']['input'];
};

export type PickZoneMutation = {
  add: PickZone;
  delete: Scalars['ID']['output'];
  edit: PickZone;
  moveCart: PickZone;
};


export type PickZoneMutationAddArgs = {
  value: PickZoneInput;
};


export type PickZoneMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PickZoneMutationEditArgs = {
  modified: PickZoneInput;
  original: PickZoneInput;
};


export type PickZoneMutationMoveCartArgs = {
  id: Scalars['ID']['input'];
  stationId?: InputMaybe<Scalars['ID']['input']>;
};

export type PrintApiMutation = {
  attachment: PrintDataMutation;
  barcodeLabel?: Maybe<PrintDataMutation>;
  locationLabels: PrintDataMutation;
  purchaseOrder?: Maybe<PrintDataMutation>;
  returnStatement?: Maybe<PrintDataMutation>;
  sale?: Maybe<PrintDataMutation>;
  testPage: PrintDataMutation;
};


export type PrintApiMutationAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
};


export type PrintApiMutationBarcodeLabelArgs = {
  barcodeHeight?: Scalars['Decimal']['input'];
  barcodeLabel: Scalars['String']['input'];
  barcodeLabelType: BarcodeLabelType;
  barcodeWidth?: Scalars['Decimal']['input'];
  displayLabel?: InputMaybe<Scalars['String']['input']>;
};


export type PrintApiMutationLocationLabelsArgs = {
  labelHeightInches?: Scalars['Decimal']['input'];
  labelWidthInches?: Scalars['Decimal']['input'];
  locationIds: Array<Scalars['ID']['input']>;
};


export type PrintApiMutationPurchaseOrderArgs = {
  isIntake?: InputMaybe<Scalars['Boolean']['input']>;
  purchaseOrderId: Scalars['ID']['input'];
};


export type PrintApiMutationReturnStatementArgs = {
  isRefund: Scalars['Boolean']['input'];
  returnId: Scalars['ID']['input'];
};


export type PrintApiMutationSaleArgs = {
  saleId: Scalars['ID']['input'];
};


export type PrintApiMutationTestPageArgs = {
  height?: InputMaybe<Scalars['Decimal']['input']>;
  type: DeviceSubType;
  width?: InputMaybe<Scalars['Decimal']['input']>;
};

export type PrintDataMutation = {
  download: Scalars['String']['output'];
  print: Scalars['Boolean']['output'];
};


export type PrintDataMutationPrintArgs = {
  printerId: Scalars['ID']['input'];
};

export type Product = {
  active: Scalars['Boolean']['output'];
  attachments: Array<Attachment>;
  barcodes: Array<ProductBarcode>;
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  dropShipMode: DropShipMode;
  firstSale?: Maybe<Sale>;
  id: Scalars['ID']['output'];
  includedProducts: Array<ProductIncludedProductMapping>;
  latestPurchaseSupplier?: Maybe<Supplier>;
  locationProducts: Array<LocationProductMapping>;
  notes?: Maybe<Scalars['String']['output']>;
  openSaleFulfillmentLineItems: Array<SaleFulfillmentLineItem>;
  package?: Maybe<Package>;
  /** This ties a package (represented as a product) to a product, which keeps track of the package's stock */
  packageId?: Maybe<Scalars['Long']['output']>;
  packageType?: Maybe<PackageType>;
  partNumbers: Array<ProductPartNumber>;
  preferredPickZone?: Maybe<PickZone>;
  preferredPickZoneId?: Maybe<Scalars['ID']['output']>;
  purchaseNotes?: Maybe<Scalars['String']['output']>;
  salesChannelListings: Array<SalesChannelListing>;
  salesChannelPrices: Array<SalesChannelPrice>;
  salesData: ProductSaleStatistics;
  shippingHeight?: Maybe<Scalars['Decimal']['output']>;
  shippingLength?: Maybe<Scalars['Decimal']['output']>;
  shippingWeight?: Maybe<Scalars['Decimal']['output']>;
  shippingWidth?: Maybe<Scalars['Decimal']['output']>;
  sku: Scalars['String']['output'];
  storageHeight?: Maybe<Scalars['Decimal']['output']>;
  storageLength?: Maybe<Scalars['Decimal']['output']>;
  storageWeight?: Maybe<Scalars['Decimal']['output']>;
  storageWidth?: Maybe<Scalars['Decimal']['output']>;
  suggestedPackage?: Maybe<Package>;
  /** Suggests a package for a stock product, acts like an override for any other package it might suggest */
  suggestedPackageId?: Maybe<Scalars['Long']['output']>;
  suggestedPackages: Array<ProductAndPackage>;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['ID']['output']>;
  supplierLatestPrice?: Maybe<Scalars['Decimal']['output']>;
  supplierSku?: Maybe<Scalars['String']['output']>;
  supplierWarehouseProducts: Array<SupplierWarehouseProductMapping>;
  totalRevenue: Scalars['Decimal']['output'];
  totalSales: Scalars['Decimal']['output'];
  type: ProductType;
  unitOfMeasure: UnitOfMeasure;
  unitOfMeasureId: Scalars['ID']['output'];
  warehouseProducts: Array<Maybe<WarehouseProductMappingInterface>>;
  zboxBarcode: Scalars['String']['output'];
};


export type ProductSalesDataArgs = {
  afterDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  beforeDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  salesChannelId?: InputMaybe<Scalars['ID']['input']>;
};


export type ProductWarehouseProductsArgs = {
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductAndPackage = {
  calculatedWeight: Scalars['Decimal']['output'];
  package: Package;
  product: Product;
};

export type ProductApiMutation = {
  addNotes: Scalars['Boolean']['output'];
  bulkImport: Scalars['Boolean']['output'];
};


export type ProductApiMutationAddNotesArgs = {
  parts: Array<ProductNoteInput>;
};


export type ProductApiMutationBulkImportArgs = {
  importProducts: Array<ProductBulkInput>;
};

export type ProductBarcode = {
  barcode: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
};

export type ProductBarcodeInput = {
  barcode: Scalars['String']['input'];
  sortOrder: Scalars['Float']['input'];
};

export type ProductBulkInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isKit: Scalars['Boolean']['input'];
  manufacturerName?: InputMaybe<Scalars['String']['input']>;
  packageType?: InputMaybe<PackageType>;
  partNumber: Scalars['String']['input'];
  photos?: InputMaybe<Array<ZbDbPartPhoto>>;
  productIncludedProductMappings?: InputMaybe<Array<BulkProductIncludedProductMappingInput>>;
  salesChannelListings?: InputMaybe<Array<BulkSalesChannelListingInput>>;
  sku: Scalars['String']['input'];
  storageHeight?: InputMaybe<Scalars['Decimal']['input']>;
  storageLength?: InputMaybe<Scalars['Decimal']['input']>;
  storageWeight?: InputMaybe<Scalars['Decimal']['input']>;
  storageWidth?: InputMaybe<Scalars['Decimal']['input']>;
  suggestedPackageId?: InputMaybe<Scalars['Long']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
};

export enum ProductCondition {
  BrokenVisibleDamage = 'BROKEN_VISIBLE_DAMAGE',
  CosmeticDamage = 'COSMETIC_DAMAGE',
  DesignFlaw = 'DESIGN_FLAW',
  ManufacturingDefect = 'MANUFACTURING_DEFECT',
  New = 'NEW',
  PartsMissing = 'PARTS_MISSING',
  Unknown = 'UNKNOWN',
  Used = 'USED'
}

/** A connection from an object to a list of objects of type 'Product'. */
export type ProductConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<ProductEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Product>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Product'. */
export type ProductEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Product;
};

export type ProductIncludedProductMapping = {
  includedProduct: Product;
  includedProductId: Scalars['ID']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  quantity: Scalars['Decimal']['output'];
};

export type ProductInput = {
  active: Scalars['Boolean']['input'];
  barcodes?: InputMaybe<Array<ProductBarcodeInput>>;
  description: Scalars['String']['input'];
  dropShipMode: DropShipMode;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  packageId?: InputMaybe<Scalars['ID']['input']>;
  partNumbers?: InputMaybe<Array<ProductPartNumberInput>>;
  purchaseNotes?: InputMaybe<Scalars['String']['input']>;
  salesChannelListings?: InputMaybe<Array<SalesChannelListingInput>>;
  salesChannelPrices?: InputMaybe<Array<SalesChannelPriceInput>>;
  sku: Scalars['String']['input'];
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  supplierLatestPrice?: InputMaybe<Scalars['Decimal']['input']>;
  supplierSku?: InputMaybe<Scalars['String']['input']>;
  type: ProductType;
  unitOfMeasureId: Scalars['ID']['input'];
  warehouseProducts?: InputMaybe<Array<WarehouseProductMappingInput>>;
};

export type ProductMutation = {
  add: Product;
  delete: Scalars['ID']['output'];
  edit: Product;
};


export type ProductMutationAddArgs = {
  value: ProductInput;
};


export type ProductMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type ProductMutationEditArgs = {
  modified: ProductInput;
  original: ProductInput;
};

export type ProductNoteInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
};

export type ProductPartNumber = {
  partNumber: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
};

export type ProductPartNumberInput = {
  partNumber: Scalars['String']['input'];
  sortOrder: Scalars['Float']['input'];
};

export type ProductSaleStatistics = {
  numberOfSales: Scalars['Int']['output'];
  totalQuantitySold: Scalars['Decimal']['output'];
  totalRevenue: Scalars['Decimal']['output'];
};

export enum ProductType {
  Kit = 'KIT',
  Product = 'PRODUCT',
  Service = 'SERVICE'
}

export enum ProductsByWarehouseSortOrder {
  AllocatedAscending = 'ALLOCATED_ASCENDING',
  AllocatedDescending = 'ALLOCATED_DESCENDING',
  AvailableAscending = 'AVAILABLE_ASCENDING',
  AvailableDescending = 'AVAILABLE_DESCENDING',
  DescriptionAscending = 'DESCRIPTION_ASCENDING',
  DescriptionDescending = 'DESCRIPTION_DESCENDING',
  IdAscending = 'ID_ASCENDING',
  IdDescending = 'ID_DESCENDING',
  LocationAscending = 'LOCATION_ASCENDING',
  LocationDescending = 'LOCATION_DESCENDING',
  MinBeforeReorderAscending = 'MIN_BEFORE_REORDER_ASCENDING',
  MinBeforeReorderDescending = 'MIN_BEFORE_REORDER_DESCENDING',
  OnHandAscending = 'ON_HAND_ASCENDING',
  OnHandDescending = 'ON_HAND_DESCENDING',
  OnOrderAscending = 'ON_ORDER_ASCENDING',
  OnOrderDescending = 'ON_ORDER_DESCENDING',
  ReorderQuantityAscending = 'REORDER_QUANTITY_ASCENDING',
  ReorderQuantityDescending = 'REORDER_QUANTITY_DESCENDING',
  SkuAscending = 'SKU_ASCENDING',
  SkuDescending = 'SKU_DESCENDING',
  SupplierAscending = 'SUPPLIER_ASCENDING',
  SupplierDescending = 'SUPPLIER_DESCENDING',
  SupplierSkuAscending = 'SUPPLIER_SKU_ASCENDING',
  SupplierSkuDescending = 'SUPPLIER_SKU_DESCENDING'
}

export enum ProductsSortOrder {
  DescriptionAscending = 'DESCRIPTION_ASCENDING',
  DescriptionDescending = 'DESCRIPTION_DESCENDING',
  IdAscending = 'ID_ASCENDING',
  IdDescending = 'ID_DESCENDING',
  PartNumberAscending = 'PART_NUMBER_ASCENDING',
  PartNumberDescending = 'PART_NUMBER_DESCENDING',
  SkuAscending = 'SKU_ASCENDING',
  SkuDescending = 'SKU_DESCENDING',
  SupplierAscending = 'SUPPLIER_ASCENDING',
  SupplierDescending = 'SUPPLIER_DESCENDING',
  SupplierSkuAscending = 'SUPPLIER_SKU_ASCENDING',
  SupplierSkuDescending = 'SUPPLIER_SKU_DESCENDING'
}

export type PurchaseInvoice = {
  arrivalDate?: Maybe<Scalars['DateOnly']['output']>;
  attachments: Array<Attachment>;
  id: Scalars['ID']['output'];
  invoiceDate: Scalars['DateOnly']['output'];
  invoiceNumber: Scalars['String']['output'];
  lineItems: Array<PurchaseInvoiceLineItem>;
  paymentTermId: Scalars['ID']['output'];
  purchaseOrder: PurchaseOrder;
  purchaseOrderId: Scalars['ID']['output'];
  status: PurchaseInvoiceStatus;
  subtotal: Scalars['Decimal']['output'];
  tax: Scalars['Decimal']['output'];
  total: Scalars['Decimal']['output'];
  trackingNumbers: Array<PurchaseInvoiceTrackingNumber>;
  zboxBarcode: Scalars['String']['output'];
};

export type PurchaseInvoiceInput = {
  arrivalDate?: InputMaybe<Scalars['DateOnly']['input']>;
  id: Scalars['ID']['input'];
  invoiceDate: Scalars['DateOnly']['input'];
  invoiceNumber: Scalars['String']['input'];
  lineItems?: InputMaybe<Array<PurchaseInvoiceLineItemInput>>;
  paymentTermId: Scalars['ID']['input'];
  purchaseOrderId: Scalars['ID']['input'];
  status: PurchaseInvoiceStatus;
  subtotal: Scalars['Decimal']['input'];
  tax: Scalars['Decimal']['input'];
  total: Scalars['Decimal']['input'];
  trackingNumbers?: InputMaybe<Array<PurchaseInvoiceTrackingNumberInput>>;
};

export type PurchaseInvoiceLineItem = {
  actualCondition?: Maybe<ProductCondition>;
  actualQuantity: Scalars['Decimal']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  discount: Scalars['Float']['output'];
  expectedQuantity: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  purchaseInvoiceId: Scalars['ID']['output'];
  purchaseOrderLineItem: PurchaseOrderLineItem;
  purchaseOrderLineItemId: Scalars['ID']['output'];
  total: Scalars['Decimal']['output'];
  unitPrice: Scalars['Decimal']['output'];
};

export type PurchaseInvoiceLineItemInput = {
  actualCondition?: InputMaybe<ProductCondition>;
  actualQuantity: Scalars['Decimal']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  discount: Scalars['Float']['input'];
  expectedQuantity: Scalars['Decimal']['input'];
  id: Scalars['ID']['input'];
  purchaseOrderLineItemId: Scalars['ID']['input'];
  total: Scalars['Decimal']['input'];
  unitPrice: Scalars['Decimal']['input'];
};

export type PurchaseInvoiceMutation = {
  add: PurchaseInvoice;
  addProductToInvoice: Scalars['Long']['output'];
  addReturnTrackingNumber: Scalars['ID']['output'];
  changeStatus: PurchaseInvoice;
  delete: Scalars['ID']['output'];
  edit: PurchaseInvoice;
};


export type PurchaseInvoiceMutationAddArgs = {
  value: PurchaseInvoiceInput;
};


export type PurchaseInvoiceMutationAddProductToInvoiceArgs = {
  productId?: InputMaybe<Scalars['ID']['input']>;
  purchaseInvoiceId: Scalars['ID']['input'];
};


export type PurchaseInvoiceMutationAddReturnTrackingNumberArgs = {
  purchaseInvoiceId: Scalars['ID']['input'];
  shippingLabelCost: Scalars['Decimal']['input'];
  shippingServiceId: Scalars['ID']['input'];
  trackingNumber: Scalars['String']['input'];
};


export type PurchaseInvoiceMutationChangeStatusArgs = {
  id: Scalars['ID']['input'];
  newStatus: PurchaseInvoiceStatus;
  oldStatus: PurchaseInvoiceStatus;
};


export type PurchaseInvoiceMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PurchaseInvoiceMutationEditArgs = {
  modified: PurchaseInvoiceInput;
  original: PurchaseInvoiceInput;
};

export enum PurchaseInvoiceStatus {
  Authorized = 'AUTHORIZED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Void = 'VOID'
}

export type PurchaseInvoiceTrackingNumber = {
  purchaseInvoiceId: Scalars['ID']['output'];
  shippingServiceId?: Maybe<Scalars['ID']['output']>;
  sortOrder: Scalars['Float']['output'];
  trackingNumber: Scalars['String']['output'];
};

export type PurchaseInvoiceTrackingNumberInput = {
  shippingServiceId?: InputMaybe<Scalars['ID']['input']>;
  sortOrder: Scalars['Float']['input'];
  trackingNumber: Scalars['String']['input'];
};

export type PurchaseOrder = {
  attachments: Array<Attachment>;
  billingAddress: Address;
  billingAddressId: Scalars['ID']['output'];
  blindReceipt: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoices: Array<PurchaseInvoice>;
  isDropShip: Scalars['Boolean']['output'];
  isReturn: Scalars['Boolean']['output'];
  lineItems: Array<PurchaseOrderLineItem>;
  notes?: Maybe<Scalars['String']['output']>;
  orderDate?: Maybe<Scalars['DateTimeOffset']['output']>;
  refSale?: Maybe<Sale>;
  refSaleId?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  returnAction?: Maybe<ReturnAction>;
  returnFault?: Maybe<ReturnFault>;
  returnReason?: Maybe<ReturnReason>;
  returnReasonId?: Maybe<Scalars['ID']['output']>;
  returnTo?: Maybe<ReturnTo>;
  shippingAddress: Address;
  shippingAddressId: Scalars['Long']['output'];
  status: PurchaseOrderStatus;
  subtotal: Scalars['Decimal']['output'];
  supplier?: Maybe<Supplier>;
  supplierAddress?: Maybe<Address>;
  supplierAddressId?: Maybe<Scalars['ID']['output']>;
  supplierContact?: Maybe<SupplierContact>;
  supplierContactId?: Maybe<Scalars['ID']['output']>;
  supplierId?: Maybe<Scalars['ID']['output']>;
  tax: Scalars['Decimal']['output'];
  total: Scalars['Decimal']['output'];
  zboxBarcode: Scalars['String']['output'];
};

/** A connection from an object to a list of objects of type 'PurchaseOrder'. */
export type PurchaseOrderConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<PurchaseOrderEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<PurchaseOrder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'PurchaseOrder'. */
export type PurchaseOrderEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: PurchaseOrder;
};

export type PurchaseOrderInput = {
  billingAddress: AddressInput;
  blindReceipt: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isDropShip: Scalars['Boolean']['input'];
  isReturn: Scalars['Boolean']['input'];
  lineItems?: InputMaybe<Array<PurchaseOrderLineItemInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  orderDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  refSaleId?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  returnAction?: InputMaybe<ReturnAction>;
  returnFault?: InputMaybe<ReturnFault>;
  returnReasonId?: InputMaybe<Scalars['ID']['input']>;
  returnTo?: InputMaybe<ReturnTo>;
  shippingAddress: AddressInput;
  status: PurchaseOrderStatus;
  subtotal: Scalars['Decimal']['input'];
  supplierAddressId?: InputMaybe<Scalars['ID']['input']>;
  supplierContactId?: InputMaybe<Scalars['ID']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  tax: Scalars['Decimal']['input'];
  total: Scalars['Decimal']['input'];
};

export type PurchaseOrderLineItem = {
  comment?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  discount: Scalars['Decimal']['output'];
  expectedCondition?: Maybe<ProductCondition>;
  id: Scalars['ID']['output'];
  isAdditionalCharge: Scalars['Boolean']['output'];
  partNumber?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']['output']>;
  purchaseOrder: PurchaseOrder;
  purchaseOrderId: Scalars['ID']['output'];
  quantity: Scalars['Decimal']['output'];
  returnOriginalUnitPrice?: Maybe<Scalars['Decimal']['output']>;
  sortOrder: Scalars['Float']['output'];
  supplierSku?: Maybe<Scalars['String']['output']>;
  total: Scalars['Decimal']['output'];
  unitOfMeasure: UnitOfMeasure;
  unitOfMeasureId: Scalars['ID']['output'];
  unitPrice: Scalars['Decimal']['output'];
};

export type PurchaseOrderLineItemInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  discount: Scalars['Decimal']['input'];
  expectedCondition?: InputMaybe<ProductCondition>;
  id: Scalars['ID']['input'];
  isAdditionalCharge: Scalars['Boolean']['input'];
  partNumber?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Decimal']['input'];
  returnOriginalUnitPrice?: InputMaybe<Scalars['Decimal']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  sortOrder: Scalars['Float']['input'];
  supplierSku?: InputMaybe<Scalars['String']['input']>;
  total: Scalars['Decimal']['input'];
  unitOfMeasureId: Scalars['ID']['input'];
  unitPrice: Scalars['Decimal']['input'];
};

export type PurchaseOrderMutation = {
  add: PurchaseOrder;
  delete: Scalars['ID']['output'];
  edit: PurchaseOrder;
};


export type PurchaseOrderMutationAddArgs = {
  value: PurchaseOrderInput;
};


export type PurchaseOrderMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PurchaseOrderMutationEditArgs = {
  modified: PurchaseOrderInput;
  original: PurchaseOrderInput;
};

export enum PurchaseOrderStatus {
  Authorized = 'AUTHORIZED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Invoiced = 'INVOICED',
  Ordered = 'ORDERED',
  PendingApproval = 'PENDING_APPROVAL',
  Received = 'RECEIVED',
  Receiving = 'RECEIVING',
  Void = 'VOID'
}

export enum PurchaseOrderType {
  DropShip = 'DROP_SHIP',
  Return = 'RETURN',
  Stock = 'STOCK',
  StockOrDropShip = 'STOCK_OR_DROP_SHIP'
}

export enum PurchaseOrdersSortOrder {
  ExpectedAscending = 'EXPECTED_ASCENDING',
  ExpectedDescending = 'EXPECTED_DESCENDING',
  IdAscending = 'ID_ASCENDING',
  IdDescending = 'ID_DESCENDING',
  OrderDateAscending = 'ORDER_DATE_ASCENDING',
  OrderDateDescending = 'ORDER_DATE_DESCENDING',
  ReceivedAscending = 'RECEIVED_ASCENDING',
  ReceivedDescending = 'RECEIVED_DESCENDING',
  StatusAscending = 'STATUS_ASCENDING',
  StatusDescending = 'STATUS_DESCENDING',
  SupplierAscending = 'SUPPLIER_ASCENDING',
  SupplierDescending = 'SUPPLIER_DESCENDING',
  TotalAscending = 'TOTAL_ASCENDING',
  TotalDescending = 'TOTAL_DESCENDING'
}

export type Query = {
  activityLog?: Maybe<ActivityLog>;
  activityLogs: ActivityLogConnection;
  address?: Maybe<Address>;
  admin: Admin;
  amazonFulfillmentCenters: AmazonFulfillmentCenterConnection;
  apiLogs: ApiLogConnection;
  attachment?: Maybe<Attachment>;
  countries: CountryConnection;
  country?: Maybe<Country>;
  device?: Maybe<Device>;
  devices: DeviceConnection;
  hiveLink?: Maybe<HiveLink>;
  hiveLinks: HiveLinkConnection;
  integrations?: Maybe<IntegrationsGraph>;
  labelProvider?: Maybe<LabelProvider>;
  labelProviders: LabelProviderConnection;
  location?: Maybe<Location>;
  locationHistories: LocationHistoryConnection;
  locationHistory?: Maybe<LocationHistory>;
  locations: LocationConnection;
  log?: Maybe<Log>;
  logs: LogConnection;
  me: UserInfo;
  mobile: MobileQuery;
  packFilter?: Maybe<PackFilter>;
  packFilters: PackFilterConnection;
  package?: Maybe<Package>;
  packages: PackageConnection;
  paymentTerm?: Maybe<PaymentTerm>;
  paymentTerms: PaymentTermConnection;
  pickEquipment?: Maybe<PickEquipment>;
  pickEquipments: PickEquipmentConnection;
  pickFilter?: Maybe<PickFilter>;
  pickFilterPickZoneMappings: PickFilterPickZoneMappingConnection;
  pickFilterPickZoneMappingsByPickZoneId: Array<PickFilterPickZoneMapping>;
  pickFilters: PickFilterConnection;
  pickZone?: Maybe<PickZone>;
  pickZones: PickZoneConnection;
  printer?: Maybe<Device>;
  printers: DeviceConnection;
  product?: Maybe<Product>;
  products: ProductConnection;
  productsByLocation: Array<LocationProductMapping>;
  productsByWarehouse: WarehouseProductMapping2Connection;
  purchaseInvoice?: Maybe<PurchaseInvoice>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchaseOrders: PurchaseOrderConnection;
  report?: Maybe<Report>;
  reports: ReportConnection;
  return?: Maybe<Return>;
  returnReason?: Maybe<ReturnReason>;
  returnReasons: ReturnReasonConnection;
  returns: ReturnConnection;
  sale?: Maybe<Sale>;
  saleFulfillment?: Maybe<SaleFulfillment>;
  saleFulfillments: SaleFulfillmentConnection;
  sales: SaleConnection;
  salesChannel?: Maybe<SalesChannel>;
  salesChannels: SalesChannelConnection;
  searchBarcodes: Array<BarcodeSearchResult>;
  setting?: Maybe<Setting>;
  settings: SettingConnection;
  shippingCarrier?: Maybe<ShippingCarrier>;
  shippingCarriers: ShippingCarrierConnection;
  shippingService?: Maybe<ShippingService>;
  shippingServices: ShippingServiceConnection;
  station?: Maybe<Station>;
  stationSet?: Maybe<StationSet>;
  stationSets: StationSetConnection;
  stockTake?: Maybe<StockTake>;
  stockTakes: StockTakeConnection;
  supplier?: Maybe<Supplier>;
  supplierContact?: Maybe<SupplierContact>;
  supplierContacts: SupplierContactConnection;
  supplierWarehouse?: Maybe<SupplierWarehouse>;
  supplierWarehouseProducts: SupplierWarehouseProductMappingConnection;
  suppliers: SupplierConnection;
  timeZones: Array<Scalars['String']['output']>;
  unitOfMeasure?: Maybe<UnitOfMeasure>;
  unitOfMeasures: UnitOfMeasureConnection;
  users: Array<UserInfo>;
  version?: Maybe<Scalars['String']['output']>;
  warehouse?: Maybe<Warehouse>;
  warehouseProducts: WarehouseProductMappingConnection;
  warehouseReturnAddresses: WarehouseReturnAddressMappingConnection;
  warehouses: WarehouseConnection;
};


export type QueryActivityLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryActivityLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  rowId?: InputMaybe<Scalars['ID']['input']>;
  table: TableEnum;
};


export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAmazonFulfillmentCentersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryApiLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAttachmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCountryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHiveLinkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHiveLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLabelProviderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLabelProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLocationHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationHistoryType?: InputMaybe<LocationHistoryType>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  purchaseInvoiceId?: InputMaybe<Scalars['ID']['input']>;
  saleFulfillmentId?: InputMaybe<Scalars['ID']['input']>;
  saleId?: InputMaybe<Scalars['ID']['input']>;
  startDateTime?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLocationHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aisle?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bin?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pickZoneId?: InputMaybe<Scalars['ID']['input']>;
  pickable?: InputMaybe<Scalars['Boolean']['input']>;
  rack?: InputMaybe<Scalars['String']['input']>;
  shelf?: InputMaybe<Scalars['String']['input']>;
  side?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: LocationsSortOrder;
  warehouseId: Scalars['ID']['input'];
};


export type QueryLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLogsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  eventIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  eventSources?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  logLevels?: InputMaybe<Array<LogLevel>>;
};


export type QueryPackFilterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPackFiltersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPackageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPackagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentTermArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPickEquipmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPickEquipmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPickFilterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPickFilterPickZoneMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPickFilterPickZoneMappingsByPickZoneIdArgs = {
  pickZoneId: Scalars['ID']['input'];
};


export type QueryPickFiltersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPickZoneArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPickZonesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPrinterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPrintersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  barcode?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skus?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: ProductsSortOrder;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductsByLocationArgs = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  pickZoneId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userEntered?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryProductsByWarehouseArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeUnassignedProducts?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyUnassignedProducts?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: ProductsByWarehouseSortOrder;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPurchaseInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPurchaseOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  relatedSaleId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: PurchaseOrdersSortOrder;
  statuses?: InputMaybe<Array<PurchaseOrderStatus>>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PurchaseOrderType>;
};


export type QueryReportArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReturnArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReturnReasonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReturnReasonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReturnsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  relatedPurchaseOrderId?: InputMaybe<Scalars['ID']['input']>;
  relatedSaleId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: ReturnsSortOrder;
  statuses?: InputMaybe<Array<ReturnStatus>>;
};


export type QuerySaleArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySaleFulfillmentArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySaleFulfillmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  shipDateFrom?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  shipDateTo?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  shippingServiceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sortOrder?: SaleFulfillmentSortOrder;
  statuses?: InputMaybe<Array<SaleFulfillmentStatus>>;
};


export type QuerySalesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  childSaleType?: InputMaybe<ChildSaleType>;
  createdSince?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  relatedPurchaseOrderId?: InputMaybe<Scalars['ID']['input']>;
  relatedSaleId?: InputMaybe<Scalars['ID']['input']>;
  salesChannelId?: InputMaybe<Scalars['ID']['input']>;
  salesChannelReference?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  shipBy?: InputMaybe<Scalars['DateOnly']['input']>;
  sortOrder?: SalesSortOrder;
  status?: InputMaybe<SaleStatus>;
  stockType?: InputMaybe<SaleStockType>;
};


export type QuerySalesChannelArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySalesChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchBarcodesArgs = {
  search: Scalars['String']['input'];
  userEntered?: InputMaybe<Scalars['Boolean']['input']>;
  vendorSpecific?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySettingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShippingCarrierArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShippingCarriersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShippingServiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShippingServicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStationSetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStationSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStockTakeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStockTakesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySupplierContactArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySupplierContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySupplierWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySupplierWarehouseProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySuppliersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUnitOfMeasureArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUnitOfMeasuresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWarehouseProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWarehouseReturnAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWarehousesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueueByTabledMetrics = {
  amountInQueue?: Maybe<Scalars['Int']['output']>;
  avgExecutionMillisecondDuration?: Maybe<Scalars['Float']['output']>;
  executionCount: Scalars['Long']['output'];
  lastExecutionMillisecondDuration?: Maybe<Scalars['Float']['output']>;
  maxExecutionMillisecondDuration?: Maybe<Scalars['Float']['output']>;
  minExecutionMillisecondDuration?: Maybe<Scalars['Float']['output']>;
  table?: Maybe<Scalars['String']['output']>;
  totalExecutionMillisecondDuration: Scalars['Float']['output'];
};

export type QueueInfo = {
  estimatedCompletionTimeMillisecond: Scalars['Float']['output'];
  itemInProcess?: Maybe<ActivityLogV2>;
  nextInQueue?: Maybe<ActivityLogV2>;
  queue: Array<Maybe<ActivityLogV2>>;
  queueLength: Scalars['Int']['output'];
  queueTableMetrics: Array<Maybe<QueueByTabledMetrics>>;
};

export type RecalculateChannelStockOutput = {
  createdPayLoad: Scalars['Boolean']['output'];
  done: Scalars['Boolean']['output'];
  errorList?: Maybe<Array<ErrorRecord>>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  listings?: Maybe<Array<StockUpdates>>;
  queryDatabase: Scalars['Boolean']['output'];
  status?: Maybe<Array<StockUpdateStatus>>;
  wasError: Scalars['Boolean']['output'];
};

export type Report = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['Complex']['output']>;
  name: Scalars['String']['output'];
};

/** A connection from an object to a list of objects of type 'Report'. */
export type ReportConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<ReportEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Report>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Report'. */
export type ReportEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Report;
};

export type ReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  metadata?: InputMaybe<Scalars['Complex']['input']>;
  name: Scalars['String']['input'];
};

export type ReportMutation = {
  edit: Report;
};


export type ReportMutationEditArgs = {
  modified: ReportInput;
  original: ReportInput;
};

export type RequiredProduct = {
  pickedProductsAtStation: Array<LocationStock>;
  pickedProductsNotAtStation: Array<LocationStock>;
  product: Product;
  productId: Scalars['ID']['output'];
  productsToPick: Array<LocationStock>;
  quantityRemainingToPick: Scalars['Decimal']['output'];
  saleId: Scalars['ID']['output'];
};

export type Return = {
  atFault: ReturnFault;
  attemptedMachineMake?: Maybe<Scalars['String']['output']>;
  attemptedMachineModel?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTimeOffset']['output']>;
  conditionDetails?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeOffset']['output'];
  id: Scalars['ID']['output'];
  internalNotes?: Maybe<Scalars['String']['output']>;
  labels: Array<ReturnLabel>;
  lastShipmentMoveDate?: Maybe<Scalars['DateTimeOffset']['output']>;
  lastShipmentMoveDelivered?: Maybe<Scalars['Boolean']['output']>;
  lineItems: Array<ReturnLineItem>;
  originalSale: Sale;
  originalSaleId: Scalars['Long']['output'];
  packageType?: Maybe<PackageType>;
  packingType?: Maybe<PackingType>;
  refundedAt?: Maybe<Scalars['DateTimeOffset']['output']>;
  reshipSale?: Maybe<Sale>;
  reshipSaleId?: Maybe<Scalars['Long']['output']>;
  returnPurchaseOrder?: Maybe<PurchaseOrder>;
  returnPurchaseOrderId?: Maybe<Scalars['Long']['output']>;
  returnReason: ReturnReason;
  returnReasonId: Scalars['Long']['output'];
  returnSalesChannelReference?: Maybe<Scalars['String']['output']>;
  status: ReturnStatus;
  totalRefund?: Maybe<Scalars['Decimal']['output']>;
};

export enum ReturnAction {
  None = 'NONE',
  Refund = 'REFUND',
  Replace = 'REPLACE'
}

export type ReturnApiMutation = {
  bulkImport: Array<Scalars['Int']['output']>;
};


export type ReturnApiMutationBulkImportArgs = {
  returns: Array<ReturnBulkInput>;
};

export type ReturnBulkInput = {
  customerComments?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  reasonForReturn: Scalars['String']['input'];
  requestedAction: Scalars['String']['input'];
  returnDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  returnId: Scalars['Int']['input'];
  salesChannelReference: Scalars['String']['input'];
  staffNotes?: InputMaybe<Scalars['String']['input']>;
};

/** A connection from an object to a list of objects of type 'Return'. */
export type ReturnConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<ReturnEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Return>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Return'. */
export type ReturnEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Return;
};

export enum ReturnFault {
  Customer = 'CUSTOMER',
  Shipper = 'SHIPPER',
  Vendor = 'VENDOR',
  Zbox = 'ZBOX'
}

export type ReturnInput = {
  atFault: ReturnFault;
  attemptedMachineMake?: InputMaybe<Scalars['String']['input']>;
  attemptedMachineModel?: InputMaybe<Scalars['String']['input']>;
  conditionDetails?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  lastShipmentMoveDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  lineItems?: InputMaybe<Array<ReturnLineItemInput>>;
  originalSaleId: Scalars['ID']['input'];
  packageType?: InputMaybe<PackageType>;
  packingType?: InputMaybe<PackingType>;
  returnReasonId: Scalars['ID']['input'];
  returnSalesChannelReference?: InputMaybe<Scalars['String']['input']>;
  status: ReturnStatus;
  totalRefund?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ReturnLabel = {
  id: Scalars['ID']['output'];
  labelAttachment?: Maybe<Attachment>;
  labelAttachmentId?: Maybe<Scalars['Long']['output']>;
  labelCost?: Maybe<Scalars['Decimal']['output']>;
  return: Return;
  returnId: Scalars['Long']['output'];
  shippingService?: Maybe<ShippingService>;
  shippingServiceId?: Maybe<Scalars['Long']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Decimal']['output']>;
};

export type ReturnLineItem = {
  condition: ProductCondition;
  id: Scalars['ID']['output'];
  isAdditionalCharge: Scalars['Boolean']['output'];
  originalSaleLineItem?: Maybe<SaleLineItem>;
  originalSaleLineItemId?: Maybe<Scalars['Long']['output']>;
  product: Product;
  productId: Scalars['Long']['output'];
  quantityReship: Scalars['Decimal']['output'];
  quantityReturn: Scalars['Decimal']['output'];
  refundTax: Scalars['Decimal']['output'];
  refundTotal: Scalars['Decimal']['output'];
  refundUnitPrice: Scalars['Decimal']['output'];
  reshipSaleLineItem?: Maybe<SaleLineItem>;
  reshipSaleLineItemId?: Maybe<Scalars['Long']['output']>;
  return: Return;
  returnId: Scalars['Long']['output'];
  returnPurchaseOrderLineItem?: Maybe<PurchaseOrderLineItem>;
  returnPurchaseOrderLineItemId?: Maybe<Scalars['Long']['output']>;
  returnTo: ReturnTo;
  sortOrder: Scalars['Float']['output'];
  unitCost?: Maybe<Scalars['Decimal']['output']>;
  unitOfMeasure: UnitOfMeasure;
  unitOfMeasureId: Scalars['Long']['output'];
};

export type ReturnLineItemInput = {
  condition: ProductCondition;
  id: Scalars['ID']['input'];
  isAdditionalCharge: Scalars['Boolean']['input'];
  originalSaleLineItemId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
  quantityReship: Scalars['Decimal']['input'];
  quantityReturn: Scalars['Decimal']['input'];
  refundTax: Scalars['Decimal']['input'];
  refundTotal: Scalars['Decimal']['input'];
  refundUnitPrice: Scalars['Decimal']['input'];
  reshipSaleLineItemId?: InputMaybe<Scalars['ID']['input']>;
  returnPurchaseOrderLineItemId?: InputMaybe<Scalars['ID']['input']>;
  returnTo: ReturnTo;
  sku?: InputMaybe<Scalars['String']['input']>;
  sortOrder: Scalars['Float']['input'];
  unitCost?: InputMaybe<Scalars['Decimal']['input']>;
  unitOfMeasureId: Scalars['ID']['input'];
};

export type ReturnMutation = {
  add: Return;
  createReturn: Scalars['Long']['output'];
  delete: Scalars['ID']['output'];
  edit: Return;
  editTrackingNumber: Return;
};


export type ReturnMutationAddArgs = {
  value: ReturnInput;
};


export type ReturnMutationCreateReturnArgs = {
  createReturnInputModel: CreateReturnInput;
};


export type ReturnMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type ReturnMutationEditArgs = {
  modified: ReturnInput;
  original: ReturnInput;
};


export type ReturnMutationEditTrackingNumberArgs = {
  modified: ReturnPurchaseInvoiceTrackingNumberInput;
  original: ReturnPurchaseInvoiceTrackingNumberInput;
};

export type ReturnPurchaseInvoiceTrackingNumberInput = {
  id: Scalars['ID']['input'];
  returnId: Scalars['ID']['input'];
  trackingNumbers?: InputMaybe<Array<PurchaseInvoiceTrackingNumberInput>>;
};

export type ReturnReason = {
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sortOrder: Scalars['Float']['output'];
};

/** A connection from an object to a list of objects of type 'ReturnReason'. */
export type ReturnReasonConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<ReturnReasonEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<ReturnReason>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'ReturnReason'. */
export type ReturnReasonEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ReturnReason;
};

export type ReturnReasonInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  sortOrder: Scalars['Float']['input'];
};

export type ReturnReasonMutation = {
  add: ReturnReason;
  delete: Scalars['ID']['output'];
  edit: ReturnReason;
};


export type ReturnReasonMutationAddArgs = {
  value: ReturnReasonInput;
};


export type ReturnReasonMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type ReturnReasonMutationEditArgs = {
  modified: ReturnReasonInput;
  original: ReturnReasonInput;
};

export enum ReturnStatus {
  Authorized = 'AUTHORIZED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  PendingRefund = 'PENDING_REFUND',
  Void = 'VOID'
}

export enum ReturnTo {
  None = 'NONE',
  Vendor = 'VENDOR',
  Zbox = 'ZBOX'
}

export enum ReturnsSortOrder {
  CreationDateAscending = 'CREATION_DATE_ASCENDING',
  CreationDateDescending = 'CREATION_DATE_DESCENDING',
  CustomerNameAscending = 'CUSTOMER_NAME_ASCENDING',
  CustomerNameDescending = 'CUSTOMER_NAME_DESCENDING',
  IdAscending = 'ID_ASCENDING',
  IdDescending = 'ID_DESCENDING',
  OriginalSaleIdAscending = 'ORIGINAL_SALE_ID_ASCENDING',
  OriginalSaleIdDescending = 'ORIGINAL_SALE_ID_DESCENDING',
  OriginalSaleTotalAscending = 'ORIGINAL_SALE_TOTAL_ASCENDING',
  OriginalSaleTotalDescending = 'ORIGINAL_SALE_TOTAL_DESCENDING',
  SalesChannelNameAscending = 'SALES_CHANNEL_NAME_ASCENDING',
  SalesChannelNameDescending = 'SALES_CHANNEL_NAME_DESCENDING'
}

export type Sale = {
  attachments: Array<Attachment>;
  billingAddress?: Maybe<Address>;
  billingAddressId?: Maybe<Scalars['ID']['output']>;
  childSaleType?: Maybe<ChildSaleType>;
  customerNotes?: Maybe<Scalars['String']['output']>;
  customerReference?: Maybe<Scalars['String']['output']>;
  dateDelivered?: Maybe<Scalars['DateTimeOffset']['output']>;
  dateShipped?: Maybe<Scalars['DateTimeOffset']['output']>;
  dateVoided?: Maybe<Scalars['DateTimeOffset']['output']>;
  dropShip: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fulfillments: Array<SaleFulfillment>;
  id: Scalars['ID']['output'];
  internalNotes?: Maybe<Scalars['String']['output']>;
  lineItemTotals: LineItemTotalsModel;
  lineItems: Array<SaleLineItem>;
  orderDate?: Maybe<Scalars['DateTimeOffset']['output']>;
  paidSubtotal?: Maybe<Scalars['Decimal']['output']>;
  paidTax?: Maybe<Scalars['Decimal']['output']>;
  parentSale?: Maybe<Sale>;
  parentSaleId?: Maybe<Scalars['ID']['output']>;
  pickable: SalePickable;
  rootSale?: Maybe<Sale>;
  rootSaleId?: Maybe<Scalars['ID']['output']>;
  saleAttributes: Array<SaleAttribute>;
  salesChannel?: Maybe<SalesChannel>;
  salesChannelId?: Maybe<Scalars['ID']['output']>;
  salesChannelReference?: Maybe<Scalars['String']['output']>;
  shipBy?: Maybe<Scalars['DateOnly']['output']>;
  shippingAddress?: Maybe<Address>;
  shippingAddressId?: Maybe<Scalars['ID']['output']>;
  shippingService?: Maybe<ShippingService>;
  shippingServiceId?: Maybe<Scalars['ID']['output']>;
  status: SaleStatus;
  /** Indicates if there are any products available locally or in a supplier warehouse, or neither; only applies when Status = Authorized or else returns null. */
  stockType?: Maybe<SaleStockType>;
  subtotal: Scalars['Decimal']['output'];
  tax: Scalars['Decimal']['output'];
  taxPercentage?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Decimal']['output'];
  warehouse?: Maybe<Warehouse>;
  warehouseId?: Maybe<Scalars['ID']['output']>;
  zboxBarcode: Scalars['String']['output'];
};

export type SaleApiMutation = {
  bulkImport: Array<Scalars['String']['output']>;
  delete: Scalars['Long']['output'];
};


export type SaleApiMutationBulkImportArgs = {
  sales: Array<SaleBulkInput>;
};


export type SaleApiMutationDeleteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  salesChannelReference?: InputMaybe<Scalars['String']['input']>;
};

export type SaleAttribute = {
  attributeType: SaleAttributeType;
  attributeValue: Scalars['String']['output'];
};

export type SaleAttributeInput = {
  attributeType: SaleAttributeType;
  attributeValue: Scalars['String']['input'];
};

export enum SaleAttributeType {
  OrderGuid = 'ORDER_GUID'
}

export type SaleBulkInput = {
  billingAddress?: InputMaybe<ApiAddressInput>;
  clubOrderGuid?: InputMaybe<Scalars['String']['input']>;
  customerNotes?: InputMaybe<Scalars['String']['input']>;
  customerReference?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  lineItems: Array<SaleBulkLineItemInput>;
  orderDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  paidSubtotal?: InputMaybe<Scalars['Decimal']['input']>;
  paidTax?: InputMaybe<Scalars['Decimal']['input']>;
  salesChannelReference: Scalars['String']['input'];
  shipBy?: InputMaybe<Scalars['DateOnly']['input']>;
  shippingAddress?: InputMaybe<ApiAddressInput>;
  shippingServiceId: Scalars['String']['input'];
  shippingSubtotal: Scalars['Decimal']['input'];
  shippingTax: Scalars['Decimal']['input'];
  subtotal: Scalars['Decimal']['input'];
  tax: Scalars['Decimal']['input'];
  total: Scalars['Decimal']['input'];
};

export type SaleBulkLineItemInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  customerReference?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Decimal']['input'];
  salesChannelLineItemId?: InputMaybe<Scalars['ID']['input']>;
  sku: Scalars['String']['input'];
  tax: Scalars['Decimal']['input'];
  total: Scalars['Decimal']['input'];
  unitPrice: Scalars['Decimal']['input'];
};

/** A connection from an object to a list of objects of type 'Sale'. */
export type SaleConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<SaleEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Sale>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Sale'. */
export type SaleEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Sale;
};

export type SaleFulfillment = {
  deliveryDate?: Maybe<Scalars['DateTimeOffset']['output']>;
  expectedDeliveryDate?: Maybe<Scalars['DateTimeOffset']['output']>;
  hasInsurance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insuranceAmount?: Maybe<Scalars['Decimal']['output']>;
  labelAttachment?: Maybe<Attachment>;
  labelAttachmentId?: Maybe<Scalars['Long']['output']>;
  labelProvider?: Maybe<LabelProvider>;
  labelProviderId?: Maybe<Scalars['ID']['output']>;
  labelProviderShipmentId?: Maybe<Scalars['String']['output']>;
  lineItems: Array<SaleFulfillmentLineItem>;
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['Long']['output']>;
  packageNumber: Scalars['Int']['output'];
  purchaseInvoice?: Maybe<PurchaseInvoice>;
  purchaseInvoiceId?: Maybe<Scalars['ID']['output']>;
  sale: Sale;
  saleId: Scalars['ID']['output'];
  shipDate?: Maybe<Scalars['DateTimeOffset']['output']>;
  shippingService?: Maybe<ShippingService>;
  shippingServiceId?: Maybe<Scalars['ID']['output']>;
  status: SaleFulfillmentStatus;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Decimal']['output']>;
  zboxBarcode: Scalars['String']['output'];
};

/** A connection from an object to a list of objects of type 'SaleFulfillment'. */
export type SaleFulfillmentConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<SaleFulfillmentEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<SaleFulfillment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'SaleFulfillment'. */
export type SaleFulfillmentEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SaleFulfillment;
};

export type SaleFulfillmentInput = {
  hasInsurance: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  insuranceAmount?: InputMaybe<Scalars['Decimal']['input']>;
  labelAttachmentId?: InputMaybe<Scalars['ID']['input']>;
  labelProviderId?: InputMaybe<Scalars['ID']['input']>;
  labelProviderShipmentId?: InputMaybe<Scalars['String']['input']>;
  packageId?: InputMaybe<Scalars['ID']['input']>;
  packageNumber: Scalars['Int']['input'];
  purchaseInvoiceId?: InputMaybe<Scalars['ID']['input']>;
  shipDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  shippingServiceId?: InputMaybe<Scalars['ID']['input']>;
  status: SaleFulfillmentStatus;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Decimal']['input']>;
};

export type SaleFulfillmentLineItem = {
  packageQuantity: Scalars['Decimal']['output'];
  saleFulfillment: SaleFulfillment;
  saleFulfillmentId: Scalars['ID']['output'];
  saleLineItem: SaleLineItem;
  saleLineItemId: Scalars['ID']['output'];
};

export enum SaleFulfillmentSortOrder {
  IdAscending = 'ID_ASCENDING',
  IdDescending = 'ID_DESCENDING',
  OrderDateAscending = 'ORDER_DATE_ASCENDING',
  OrderDateDescending = 'ORDER_DATE_DESCENDING'
}

export enum SaleFulfillmentStatus {
  Delivered = 'DELIVERED',
  InProgress = 'IN_PROGRESS',
  LocallyPickedUp = 'LOCALLY_PICKED_UP',
  Shipped = 'SHIPPED'
}

export type SaleInput = {
  billingAddress?: InputMaybe<AddressInput>;
  customerNotes?: InputMaybe<Scalars['String']['input']>;
  customerReference?: InputMaybe<Scalars['String']['input']>;
  dropShip: Scalars['Boolean']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  lineItems?: InputMaybe<Array<SaleLineItemInput>>;
  orderDate?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  paidSubtotal?: InputMaybe<Scalars['Decimal']['input']>;
  paidTax?: InputMaybe<Scalars['Decimal']['input']>;
  saleAttributes?: InputMaybe<Array<SaleAttributeInput>>;
  saleFulfillments?: InputMaybe<Array<SaleFulfillmentInput>>;
  salesChannelId?: InputMaybe<Scalars['ID']['input']>;
  salesChannelReference?: InputMaybe<Scalars['String']['input']>;
  shipBy?: InputMaybe<Scalars['DateOnly']['input']>;
  shippingAddress?: InputMaybe<AddressInput>;
  shippingServiceId?: InputMaybe<Scalars['ID']['input']>;
  status: SaleStatus;
  subtotal: Scalars['Decimal']['input'];
  tax: Scalars['Decimal']['input'];
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
  total: Scalars['Decimal']['input'];
};

export type SaleLineItem = {
  comment?: Maybe<Scalars['String']['output']>;
  costOfGoodsShipped?: Maybe<Scalars['Decimal']['output']>;
  customerReference?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  fulfillmentLineItems: Array<SaleFulfillmentLineItem>;
  id: Scalars['ID']['output'];
  isAdditionalCharge: Scalars['Boolean']['output'];
  partNumber?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']['output']>;
  quantity: Scalars['Decimal']['output'];
  quantityBackordered: Scalars['Decimal']['output'];
  quantityPicked: Scalars['Decimal']['output'];
  quantityPreviouslyShipped: Scalars['Decimal']['output'];
  quantityShipped: Scalars['Decimal']['output'];
  quantityShippedSeparately: Scalars['Decimal']['output'];
  sale: Sale;
  saleId: Scalars['ID']['output'];
  salesChannelListing: SalesChannelListing;
  salesChannelListingId?: Maybe<Scalars['ID']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  sortOrder: Scalars['Float']['output'];
  tax: Scalars['Decimal']['output'];
  total: Scalars['Decimal']['output'];
  unitOfMeasure: UnitOfMeasure;
  unitOfMeasureId: Scalars['ID']['output'];
  unitPrice: Scalars['Decimal']['output'];
};

export type SaleLineItemInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  costOfGoodsShipped?: InputMaybe<Scalars['Decimal']['input']>;
  customerReference?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isAdditionalCharge: Scalars['Boolean']['input'];
  partNumber?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  quantity: Scalars['Decimal']['input'];
  quantityBackordered: Scalars['Decimal']['input'];
  quantityPicked: Scalars['Decimal']['input'];
  quantityPreviouslyShipped: Scalars['Decimal']['input'];
  quantityShipped: Scalars['Decimal']['input'];
  quantityShippedSeparately: Scalars['Decimal']['input'];
  salesChannelLineItemId?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  sortOrder: Scalars['Float']['input'];
  tax: Scalars['Decimal']['input'];
  total: Scalars['Decimal']['input'];
  unitOfMeasureId: Scalars['ID']['input'];
  unitPrice: Scalars['Decimal']['input'];
};

export type SaleMutation = {
  add: Sale;
  archive: Sale;
  changeStatus: Sale;
  createPurchaseOrderFromSale: Scalars['Int']['output'];
  createPurchaseOrdersFromSales: Scalars['Int']['output'];
  edit: Sale;
  reship: Sale;
  void: Scalars['Boolean']['output'];
};


export type SaleMutationAddArgs = {
  value: SaleInput;
};


export type SaleMutationArchiveArgs = {
  value: ArchivedSaleInput;
};


export type SaleMutationChangeStatusArgs = {
  id: Scalars['ID']['input'];
  newStatus: SaleStatus;
  oldStatus: SaleStatus;
};


export type SaleMutationCreatePurchaseOrderFromSaleArgs = {
  id: Scalars['ID']['input'];
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};


export type SaleMutationCreatePurchaseOrdersFromSalesArgs = {
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};


export type SaleMutationEditArgs = {
  modified: SaleInput;
  original: SaleInput;
};


export type SaleMutationReshipArgs = {
  id: Scalars['ID']['input'];
};


export type SaleMutationVoidArgs = {
  id: Scalars['ID']['input'];
};

export enum SalePickable {
  Full = 'FULL',
  None = 'NONE',
  Partial = 'PARTIAL'
}

export enum SaleStatus {
  Authorized = 'AUTHORIZED',
  Completed = 'COMPLETED',
  Delivered = 'DELIVERED',
  Draft = 'DRAFT',
  DropShipping = 'DROP_SHIPPING',
  Fulfilling = 'FULFILLING',
  Void = 'VOID'
}

export enum SaleStockType {
  Backordered = 'BACKORDERED',
  DropShip = 'DROP_SHIP',
  Stock = 'STOCK'
}

export type SalesChannel = {
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shippingServices: Array<SalesChannelShippingServiceMapping>;
  sortOrder: Scalars['Float']['output'];
};

/** A connection from an object to a list of objects of type 'SalesChannel'. */
export type SalesChannelConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<SalesChannelEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<SalesChannel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'SalesChannel'. */
export type SalesChannelEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SalesChannel;
};

export type SalesChannelInput = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  shippingServices?: InputMaybe<Array<SalesChannelShippingServiceMappingInput>>;
  sortOrder: Scalars['Float']['input'];
};

export type SalesChannelListing = {
  listingSku: Scalars['String']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  referenceNumber: Scalars['String']['output'];
  salesChannel: SalesChannel;
  salesChannelId: Scalars['ID']['output'];
};

export type SalesChannelListingInput = {
  id: Scalars['ID']['input'];
  listingSku: Scalars['String']['input'];
  referenceNumber: Scalars['String']['input'];
  salesChannelId: Scalars['ID']['input'];
  zbDbListingId?: InputMaybe<Scalars['String']['input']>;
};

export type SalesChannelMutation = {
  add: SalesChannel;
  delete: Scalars['ID']['output'];
  edit: SalesChannel;
};


export type SalesChannelMutationAddArgs = {
  value: SalesChannelInput;
};


export type SalesChannelMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type SalesChannelMutationEditArgs = {
  modified: SalesChannelInput;
  original: SalesChannelInput;
};

export type SalesChannelPrice = {
  dropShipPrice: Scalars['Decimal']['output'];
  minDropShipPrice?: Maybe<Scalars['Decimal']['output']>;
  minPrice?: Maybe<Scalars['Decimal']['output']>;
  price: Scalars['Decimal']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  salesChannel: SalesChannel;
  salesChannelId: Scalars['ID']['output'];
  stockOnHand: Scalars['Decimal']['output'];
};

export type SalesChannelPriceInput = {
  dropShipPrice: Scalars['Decimal']['input'];
  minDropShipPrice?: InputMaybe<Scalars['Decimal']['input']>;
  minPrice?: InputMaybe<Scalars['Decimal']['input']>;
  price: Scalars['Decimal']['input'];
  salesChannelId: Scalars['ID']['input'];
  stockOnHand: Scalars['Decimal']['input'];
};

export type SalesChannelShippingServiceMapping = {
  salesChannel: SalesChannel;
  salesChannelId: Scalars['ID']['output'];
  shippingCarrierCode: Scalars['String']['output'];
  shippingService: ShippingService;
  shippingServiceCode: Scalars['String']['output'];
  shippingServiceId: Scalars['ID']['output'];
  shouldMatch: Scalars['Boolean']['output'];
};

export type SalesChannelShippingServiceMappingInput = {
  shippingCarrierCode: Scalars['String']['input'];
  shippingServiceCode: Scalars['String']['input'];
  shippingServiceId: Scalars['ID']['input'];
  shouldMatch: Scalars['Boolean']['input'];
};

export enum SalesSortOrder {
  DeliveredDateAscending = 'DELIVERED_DATE_ASCENDING',
  DeliveredDateDescending = 'DELIVERED_DATE_DESCENDING',
  IdAscending = 'ID_ASCENDING',
  IdDescending = 'ID_DESCENDING',
  OrderDateAscending = 'ORDER_DATE_ASCENDING',
  OrderDateDescending = 'ORDER_DATE_DESCENDING',
  SalesChannelNameAscending = 'SALES_CHANNEL_NAME_ASCENDING',
  SalesChannelNameDescending = 'SALES_CHANNEL_NAME_DESCENDING',
  SalesChannelReferenceAscending = 'SALES_CHANNEL_REFERENCE_ASCENDING',
  SalesChannelReferenceDescending = 'SALES_CHANNEL_REFERENCE_DESCENDING',
  ShippedDateAscending = 'SHIPPED_DATE_ASCENDING',
  ShippedDateDescending = 'SHIPPED_DATE_DESCENDING',
  ShippingMethodAscending = 'SHIPPING_METHOD_ASCENDING',
  ShippingMethodDescending = 'SHIPPING_METHOD_DESCENDING',
  ShipByAscending = 'SHIP_BY_ASCENDING',
  ShipByDescending = 'SHIP_BY_DESCENDING',
  ShipToAscending = 'SHIP_TO_ASCENDING',
  ShipToDescending = 'SHIP_TO_DESCENDING',
  StatusAscending = 'STATUS_ASCENDING',
  StatusDescending = 'STATUS_DESCENDING',
  TotalAscending = 'TOTAL_ASCENDING',
  TotalDescending = 'TOTAL_DESCENDING',
  TypeAscending = 'TYPE_ASCENDING',
  TypeDescending = 'TYPE_DESCENDING',
  VoidedDateAscending = 'VOIDED_DATE_ASCENDING',
  VoidedDateDescending = 'VOIDED_DATE_DESCENDING'
}

export type SearchResultUnion = Location | PickZone | Product | PurchaseInvoice | PurchaseOrder | Return | Sale | SaleFulfillment | StationSet | Supplier | Warehouse;

export enum SelectionType {
  /** Indicates that the product or location was indirectly selected, requiring no input */
  None = 'NONE',
  /** Indicates that the user scanned a barcode to find the product or location */
  Scanned = 'SCANNED',
  /** Indicates that the user used a search box to find the product or location */
  Searched = 'SEARCHED',
  /** Indicates that the user selected the product or location off of a list */
  Selected = 'SELECTED'
}

export type ServerInfo = {
  collectedMemoryUsage: Scalars['Long']['output'];
  hiveVersion?: Maybe<Scalars['String']['output']>;
  is64BitOperatingSystem: Scalars['Boolean']['output'];
  is64BitProcess: Scalars['Boolean']['output'];
  logicalProcessors: Scalars['Int']['output'];
  memoryUsage: Scalars['Long']['output'];
  platform: Scalars['String']['output'];
  sqlClientVersion: Scalars['String']['output'];
  systemPageSize: Scalars['Int']['output'];
};

export type Setting = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  secret: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** A connection from an object to a list of objects of type 'Setting'. */
export type SettingConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<SettingEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Setting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'Setting'. */
export type SettingEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Setting;
};

export type SettingInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  secret: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export type SettingMutation = {
  add: Setting;
  delete: Scalars['ID']['output'];
  edit: Setting;
};


export type SettingMutationAddArgs = {
  value: SettingInput;
};


export type SettingMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type SettingMutationEditArgs = {
  modified: SettingInput;
  original: SettingInput;
};

export type ShippingCarrier = {
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  services: Array<ShippingService>;
  sortOrder: Scalars['Float']['output'];
};

/** A connection from an object to a list of objects of type 'ShippingCarrier'. */
export type ShippingCarrierConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<ShippingCarrierEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<ShippingCarrier>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'ShippingCarrier'. */
export type ShippingCarrierEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ShippingCarrier;
};

export type ShippingCarrierInput = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  services?: InputMaybe<Array<ShippingServiceInput>>;
  sortOrder: Scalars['Float']['input'];
};

export type ShippingCarrierMutation = {
  add: ShippingCarrier;
  delete: Scalars['ID']['output'];
  edit: ShippingCarrier;
};


export type ShippingCarrierMutationAddArgs = {
  value: ShippingCarrierInput;
};


export type ShippingCarrierMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type ShippingCarrierMutationEditArgs = {
  modified: ShippingCarrierInput;
  original: ShippingCarrierInput;
};

export type ShippingLabelAdvancedOptionsInput = {
  billToAccount?: InputMaybe<Scalars['String']['input']>;
  billToCountryCode?: InputMaybe<Scalars['String']['input']>;
  billToParty?: InputMaybe<Scalars['String']['input']>;
  billToPostalCode?: InputMaybe<Scalars['String']['input']>;
};

export type ShippingLabelApiMutation = {
  attachLabelToFulfillment: PrintDataMutation;
  /**
   * Generates shipping labels in bulk for all items at a pack station.
   * Each item is put in a fulfillment and completed if label creation is successful.
   * All items must be the same. If the item is part of a pack, a new fulfillment
   * is created for each item in the pack and the pack is added to the last fulfillment created.
   */
  bulkGenerateShippingLabels: BulkGenerateLabels;
  generateManualShippingLabel: PrintDataMutation;
  generateShippingLabel: PrintDataMutation;
  reprintLabel?: Maybe<PrintDataMutation>;
};


export type ShippingLabelApiMutationAttachLabelToFulfillmentArgs = {
  attachmentId: Scalars['ID']['input'];
  saleFulfillmentId: Scalars['ID']['input'];
};


export type ShippingLabelApiMutationBulkGenerateShippingLabelsArgs = {
  labelProviderId?: InputMaybe<Scalars['ID']['input']>;
  stationId: Scalars['ID']['input'];
};


export type ShippingLabelApiMutationGenerateManualShippingLabelArgs = {
  labelProviderId?: InputMaybe<Scalars['ID']['input']>;
  shipToAddressId: Scalars['ID']['input'];
  shippingLabelAdvancedOptionsInputModel?: InputMaybe<ShippingLabelAdvancedOptionsInput>;
  shippingServiceId: Scalars['ID']['input'];
  warehouseId: Scalars['ID']['input'];
  weight: Scalars['Decimal']['input'];
};


export type ShippingLabelApiMutationGenerateShippingLabelArgs = {
  labelProviderId?: InputMaybe<Scalars['ID']['input']>;
  saleFulfillmentId: Scalars['ID']['input'];
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  weight?: InputMaybe<Scalars['Decimal']['input']>;
};


export type ShippingLabelApiMutationReprintLabelArgs = {
  saleFulfillmentId: Scalars['ID']['input'];
};

export type ShippingService = {
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  dimensionalWeightDivisor?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  isReturnService: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shippingCarrier: ShippingCarrier;
  shippingCarrierId: Scalars['ID']['output'];
  sortOrder: Scalars['Float']['output'];
};

/** A connection from an object to a list of objects of type 'ShippingService'. */
export type ShippingServiceConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<ShippingServiceEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<ShippingService>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'ShippingService'. */
export type ShippingServiceEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ShippingService;
};

export type ShippingServiceInput = {
  active: Scalars['Boolean']['input'];
  dimensionalWeightDivisor?: InputMaybe<Scalars['Decimal']['input']>;
  id: Scalars['ID']['input'];
  isReturnService: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  sortOrder: Scalars['Float']['input'];
};

export type Station = {
  carts: Array<PickZone>;
  id: Scalars['ID']['output'];
  maxCarts: Scalars['Int']['output'];
  stationSet: StationSet;
  stationSetId: Scalars['ID']['output'];
  targetCarts: Scalars['Int']['output'];
  type: StationType;
};

export type StationInput = {
  id: Scalars['ID']['input'];
  maxCarts: Scalars['Int']['input'];
  targetCarts: Scalars['Int']['input'];
  type: StationType;
};

export type StationSet = {
  active: Scalars['Boolean']['output'];
  devices: Array<Device>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** A set of bins containing inventory that does not need to be picked; they can be packed directly at this station set. */
  preferredPickZone?: Maybe<PickZone>;
  /** A set of bins containing inventory that does not need to be picked; they can be packed directly at this station set. */
  preferredPickZoneId?: Maybe<Scalars['ID']['output']>;
  printers: Array<Device>;
  /** A set of bins to be used to stash inventory while packing or similar; these bins may be affixed to the station (desk) itself.  This is not the 'preferred pick zone' for the station set containing fast-moving items. */
  stashPickZone?: Maybe<PickZone>;
  /** A set of bins to be used to stash inventory while packing or similar; these bins may be affixed to the station (desk) itself.  This is not the 'preferred pick zone' for the station set containing fast-moving items. */
  stashPickZoneId?: Maybe<Scalars['ID']['output']>;
  stations: Array<Station>;
  type: StationSetType;
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
};

/** A connection from an object to a list of objects of type 'StationSet'. */
export type StationSetConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<StationSetEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<StationSet>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'StationSet'. */
export type StationSetEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: StationSet;
};

export type StationSetInput = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  preferredPickZoneId?: InputMaybe<Scalars['ID']['input']>;
  stashPickZoneId?: InputMaybe<Scalars['ID']['input']>;
  stations?: InputMaybe<Array<StationInput>>;
  type: StationSetType;
  warehouseId: Scalars['ID']['input'];
};

export type StationSetMutation = {
  add: StationSet;
  delete: Scalars['ID']['output'];
  edit: StationSet;
};


export type StationSetMutationAddArgs = {
  value: StationSetInput;
};


export type StationSetMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type StationSetMutationEditArgs = {
  modified: StationSetInput;
  original: StationSetInput;
};

export enum StationSetType {
  Intake = 'INTAKE',
  Pack = 'PACK',
  Spare = 'SPARE'
}

export enum StationType {
  Input = 'INPUT',
  Output = 'OUTPUT',
  Process = 'PROCESS'
}

export type StockTake = {
  date: Scalars['DateTimeOffset']['output'];
  id: Scalars['ID']['output'];
};

/** A connection from an object to a list of objects of type 'StockTake'. */
export type StockTakeConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<StockTakeEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<StockTake>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'StockTake'. */
export type StockTakeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: StockTake;
};

export type StockUpdateStatus = {
  apiErrorMessage?: Maybe<Scalars['String']['output']>;
  apiSuccess: Scalars['Boolean']['output'];
  channelId: Scalars['Long']['output'];
  dbErrorMessage?: Maybe<Scalars['String']['output']>;
  dbSuccess: Scalars['Boolean']['output'];
  generalErrorMessage?: Maybe<Scalars['String']['output']>;
  listingCount?: Maybe<Scalars['Int']['output']>;
};

export type StockUpdates = {
  listingId: Scalars['String']['output'];
  prevQuantity?: Maybe<Scalars['Decimal']['output']>;
  productId: Scalars['ID']['output'];
  quantity: Scalars['Decimal']['output'];
  salesChannelId: Scalars['Long']['output'];
  salesChannelSku: Scalars['String']['output'];
  warehouseId: Scalars['Long']['output'];
};

export type Subscription = {
  bulkRecalculateChannelStock: RecalculateChannelStockOutput;
  importSupplierStock: RecalculateChannelStockOutput;
  locationHistory: LocationHistory2;
  recalculateChannelStock: RecalculateChannelStockOutput;
  /** Generates random numbers between the specified numbers until the magic number is generated */
  rollDice: DiceStatus;
};


export type SubscriptionBulkRecalculateChannelStockArgs = {
  base64: Scalars['String']['input'];
};


export type SubscriptionImportSupplierStockArgs = {
  base64: Scalars['String']['input'];
};


export type SubscriptionLocationHistoryArgs = {
  replayAfterId?: InputMaybe<Scalars['Long']['input']>;
};


export type SubscriptionRecalculateChannelStockArgs = {
  productIds?: InputMaybe<Array<Scalars['Long']['input']>>;
};


export type SubscriptionRollDiceArgs = {
  crash: Scalars['Int']['input'];
  magic: Scalars['Int']['input'];
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
};

export type Supplier = {
  active: Scalars['Boolean']['output'];
  addresses: Array<SupplierAddressMapping>;
  contacts: Array<SupplierContact>;
  defaultPaymentTerm: PaymentTerm;
  defaultPaymentTermId: Scalars['ID']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  warehouses: Array<SupplierWarehouse>;
  zboxBarcode: Scalars['String']['output'];
};

export type SupplierAddressInput = {
  address: AddressInput;
  sortOrder: Scalars['Float']['input'];
};

export type SupplierAddressMapping = {
  address: Address;
  sortOrder: Scalars['Float']['output'];
};

/** A connection from an object to a list of objects of type 'Supplier'. */
export type SupplierConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<SupplierEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Supplier>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

export type SupplierContact = {
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mobilePhone?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  sortOrder: Scalars['Float']['output'];
  supplier: Supplier;
  supplierId: Scalars['ID']['output'];
};

/** A connection from an object to a list of objects of type 'SupplierContact'. */
export type SupplierContactConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<SupplierContactEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<SupplierContact>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'SupplierContact'. */
export type SupplierContactEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SupplierContact;
};

export type SupplierContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  sortOrder: Scalars['Float']['input'];
};

/** An edge in a connection from an object to another object of type 'Supplier'. */
export type SupplierEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Supplier;
};

export type SupplierInput = {
  active: Scalars['Boolean']['input'];
  addresses?: InputMaybe<Array<SupplierAddressInput>>;
  contacts?: InputMaybe<Array<SupplierContactInput>>;
  defaultPaymentTermId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  warehouses?: InputMaybe<Array<SupplierWarehouseInput>>;
};

export type SupplierMutation = {
  add: Supplier;
  delete: Scalars['ID']['output'];
  edit: Supplier;
};


export type SupplierMutationAddArgs = {
  value: SupplierInput;
};


export type SupplierMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type SupplierMutationEditArgs = {
  modified: SupplierInput;
  original: SupplierInput;
};

export type SupplierWarehouse = {
  active: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['ID']['output']>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  products: Array<SupplierWarehouseProductMapping>;
  sortOrder: Scalars['Float']['output'];
  stockLastUpdated?: Maybe<Scalars['DateTimeOffset']['output']>;
  supplier: Supplier;
  supplierId: Scalars['ID']['output'];
};

export type SupplierWarehouseInput = {
  active: Scalars['Boolean']['input'];
  address?: InputMaybe<AddressInput>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  products?: InputMaybe<Array<SupplierWarehouseProductMappingInput>>;
  sortOrder: Scalars['Float']['input'];
  stockLastUpdated?: InputMaybe<Scalars['DateTimeOffset']['input']>;
};

export type SupplierWarehouseProductMapping = {
  product: Product;
  productId: Scalars['ID']['output'];
  qtyInStock?: Maybe<Scalars['Decimal']['output']>;
  supplierWarehouse: SupplierWarehouse;
  supplierWarehouseId: Scalars['ID']['output'];
  toBeShipped: Scalars['Decimal']['output'];
};

/** A connection from an object to a list of objects of type 'SupplierWarehouseProductMapping'. */
export type SupplierWarehouseProductMappingConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<SupplierWarehouseProductMappingEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<SupplierWarehouseProductMapping>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'SupplierWarehouseProductMapping'. */
export type SupplierWarehouseProductMappingEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SupplierWarehouseProductMapping;
};

export type SupplierWarehouseProductMappingInput = {
  qtyInStock?: InputMaybe<Scalars['Decimal']['input']>;
  sku: Scalars['String']['input'];
};

export enum TableEnum {
  Country = 'COUNTRY',
  Device = 'DEVICE',
  HiveLink = 'HIVE_LINK',
  LabelProvider = 'LABEL_PROVIDER',
  Location = 'LOCATION',
  Package = 'PACKAGE',
  PackFilter = 'PACK_FILTER',
  PaymentTerm = 'PAYMENT_TERM',
  PickEquipment = 'PICK_EQUIPMENT',
  PickFilter = 'PICK_FILTER',
  PickFilterPickZoneMapping = 'PICK_FILTER_PICK_ZONE_MAPPING',
  PickZone = 'PICK_ZONE',
  Product = 'PRODUCT',
  PurchaseInvoice = 'PURCHASE_INVOICE',
  PurchaseOrder = 'PURCHASE_ORDER',
  Report = 'REPORT',
  Return = 'RETURN',
  ReturnReason = 'RETURN_REASON',
  Sale = 'SALE',
  SalesChannel = 'SALES_CHANNEL',
  Setting = 'SETTING',
  ShippingCarrier = 'SHIPPING_CARRIER',
  StationSet = 'STATION_SET',
  Supplier = 'SUPPLIER',
  UnitOfMeasure = 'UNIT_OF_MEASURE',
  Warehouse = 'WAREHOUSE'
}

export enum TaskStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Faulted = 'FAULTED',
  RanToCompletion = 'RAN_TO_COMPLETION',
  Running = 'RUNNING',
  WaitingForActivation = 'WAITING_FOR_ACTIVATION',
  WaitingForChildrenToComplete = 'WAITING_FOR_CHILDREN_TO_COMPLETE',
  WaitingToRun = 'WAITING_TO_RUN'
}

export type UnitOfMeasure = {
  abbreviation: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
};

/** A connection from an object to a list of objects of type 'UnitOfMeasure'. */
export type UnitOfMeasureConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<UnitOfMeasureEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<UnitOfMeasure>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'UnitOfMeasure'. */
export type UnitOfMeasureEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: UnitOfMeasure;
};

export type User = {
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserInfo = {
  activeData?: Maybe<Scalars['String']['output']>;
  activeRole: UserRole;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastActiveDate: Scalars['DateTimeOffset']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  scores: Array<UserScore>;
  stationSet?: Maybe<StationSet>;
  stationSetId?: Maybe<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
};


export type UserInfoScoresArgs = {
  from: Scalars['DateOnly']['input'];
  to?: InputMaybe<Scalars['DateOnly']['input']>;
};

export type UserMutation = {
  setMeActive: Scalars['String']['output'];
};


export type UserMutationSetMeActiveArgs = {
  activeData?: InputMaybe<Scalars['String']['input']>;
  activeRole?: InputMaybe<UserRole>;
  stationSetId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserQueryFilter = {
  equalitySymbol: Scalars['String']['input'];
  property: Scalars['String']['input'];
  table: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum UserRole {
  Idle = 'IDLE',
  Intake = 'INTAKE',
  Pack = 'PACK',
  Pick = 'PICK',
  StockTake = 'STOCK_TAKE',
  Stow = 'STOW'
}

export type UserScore = {
  date: Scalars['DateOnly']['output'];
  score: Scalars['Int']['output'];
  type: UserScoreType;
};

export enum UserScoreType {
  BinAdjustment = 'BIN_ADJUSTMENT',
  Intake = 'INTAKE',
  Pack = 'PACK',
  Pick = 'PICK',
  Return = 'RETURN',
  StockTake = 'STOCK_TAKE',
  Transfer = 'TRANSFER',
  Unknown = 'UNKNOWN'
}

export type Warehouse = {
  active: Scalars['Boolean']['output'];
  address: Address;
  addressId: Scalars['ID']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pickZones: Array<PickZone>;
  returnAddresses: Array<WarehouseReturnAddressMapping>;
  stationSets: Array<StationSet>;
  zboxBarcode: Scalars['String']['output'];
};

export type WarehouseBundleMapping = WarehouseProductMappingInterface & {
  allocated: Scalars['Decimal']['output'];
  available: Scalars['Decimal']['output'];
  minimumBeforeReorder?: Maybe<Scalars['Decimal']['output']>;
  onOrder: Scalars['Decimal']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  reorderQuantity?: Maybe<Scalars['Decimal']['output']>;
  stockOnHand: Scalars['Decimal']['output'];
  toBeShipped: Scalars['Decimal']['output'];
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
};

/** A connection from an object to a list of objects of type 'Warehouse'. */
export type WarehouseConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<WarehouseEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<Warehouse>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseConsistencyCheckModelGraph = {
  allocated: Scalars['Decimal']['output'];
  correctAllocated: Scalars['Decimal']['output'];
  correctStockOnHand: Scalars['Decimal']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  stockOnHand: Scalars['Decimal']['output'];
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
};

/** An edge in a connection from an object to another object of type 'Warehouse'. */
export type WarehouseEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Warehouse;
};

export type WarehouseInput = {
  active: Scalars['Boolean']['input'];
  address?: InputMaybe<AddressInput>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  returnAddresses?: InputMaybe<Array<WarehouseReturnAddressMappingInput>>;
};

export type WarehouseMutation = {
  add: Warehouse;
  delete: Scalars['ID']['output'];
  edit: Warehouse;
};


export type WarehouseMutationAddArgs = {
  value: WarehouseInput;
};


export type WarehouseMutationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type WarehouseMutationEditArgs = {
  modified: WarehouseInput;
  original: WarehouseInput;
};

export type WarehouseProductMapping = WarehouseProductMappingInterface & {
  allocated: Scalars['Decimal']['output'];
  available: Scalars['Decimal']['output'];
  minimumBeforeReorder?: Maybe<Scalars['Decimal']['output']>;
  onOrder: Scalars['Decimal']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  reorderQuantity?: Maybe<Scalars['Decimal']['output']>;
  stockOnHand: Scalars['Decimal']['output'];
  toBeShipped: Scalars['Decimal']['output'];
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
};

export type WarehouseProductMapping2 = {
  allocated: Scalars['Decimal']['output'];
  available: Scalars['Decimal']['output'];
  minimumBeforeReorder?: Maybe<Scalars['Decimal']['output']>;
  onOrder: Scalars['Decimal']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  reorderQuantity?: Maybe<Scalars['Decimal']['output']>;
  stockOnHand: Scalars['Decimal']['output'];
  warehouse?: Maybe<Warehouse>;
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

/** A connection from an object to a list of objects of type 'WarehouseProductMapping2'. */
export type WarehouseProductMapping2Connection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<WarehouseProductMapping2Edge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<WarehouseProductMapping2>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'WarehouseProductMapping2'. */
export type WarehouseProductMapping2Edge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: WarehouseProductMapping2;
};

/** A connection from an object to a list of objects of type 'WarehouseProductMapping'. */
export type WarehouseProductMappingConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<WarehouseProductMappingEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<WarehouseProductMapping>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'WarehouseProductMapping'. */
export type WarehouseProductMappingEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: WarehouseProductMapping;
};

export type WarehouseProductMappingInput = {
  warehouseId: Scalars['ID']['input'];
};

export type WarehouseProductMappingInterface = {
  allocated: Scalars['Decimal']['output'];
  available: Scalars['Decimal']['output'];
  minimumBeforeReorder?: Maybe<Scalars['Decimal']['output']>;
  onOrder: Scalars['Decimal']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  reorderQuantity?: Maybe<Scalars['Decimal']['output']>;
  stockOnHand: Scalars['Decimal']['output'];
  toBeShipped: Scalars['Decimal']['output'];
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
};

export type WarehouseReturnAddressMapping = {
  address: Address;
  addressId: Scalars['ID']['output'];
  salesChannel: SalesChannel;
  salesChannelId: Scalars['ID']['output'];
};

/** A connection from an object to a list of objects of type 'WarehouseReturnAddressMapping'. */
export type WarehouseReturnAddressMappingConnection = {
  /** A list of all of the edges returned in the connection. */
  edges: Array<WarehouseReturnAddressMappingEdge>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items: Array<WarehouseReturnAddressMapping>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection from an object to another object of type 'WarehouseReturnAddressMapping'. */
export type WarehouseReturnAddressMappingEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: WarehouseReturnAddressMapping;
};

export type WarehouseReturnAddressMappingInput = {
  address: AddressInput;
  salesChannelId: Scalars['ID']['input'];
};

export type ZbDbIntegration = {
  test: Scalars['Boolean']['output'];
  tokens: GraphQlAuth;
};

export type ZbDbPartPhoto = {
  photoUri?: InputMaybe<Scalars['String']['input']>;
};

export type ZboxIntegration = {
  test: Scalars['Boolean']['output'];
  tokens: GraphQlAuth;
};

export type SelectListDisplayAreasToPickQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  displayPicksFiltersInputModel: DisplayPicksFiltersInput;
}>;


export type SelectListDisplayAreasToPickQuery = { mobile: { displayAreasToPick: Array<{ aisle?: string | null, quantity: number, otherCartInAisle: boolean, equipment: { id: string, name: string } }> } };

export type SelectListFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type SelectListFiltersQuery = { salesChannels: { items: Array<{ id: string, name: string }> } };

export type GetProductPricesProductPricesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetProductPricesProductPricesQuery = { product?: { id: string, type: ProductType, sku: string, unitOfMeasureId: string, active: boolean, description: string, dropShipMode: DropShipMode, salesChannelPrices: Array<{ salesChannelId: string, price: number, stockOnHand: number, dropShipPrice: number, minPrice?: number | null, minDropShipPrice?: number | null }> } | null };

export type SaveProductPricesProductPricesMutationVariables = Exact<{
  original: ProductInput;
  modified: ProductInput;
}>;


export type SaveProductPricesProductPricesMutation = { product: { edit: { id: string, active: boolean, description: string, dropShipMode: DropShipMode, sku: string, type: ProductType, unitOfMeasureId: string, salesChannelPrices: Array<{ price: number, salesChannelId: string, stockOnHand: number, dropShipPrice: number, minPrice?: number | null, minDropShipPrice?: number | null }> } } };

export type GetSalesChannelsProductPricesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSalesChannelsProductPricesQuery = { salesChannels: { items: Array<{ id: string, name: string, active: boolean }> } };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any> | undefined) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const SelectListDisplayAreasToPickDocument = new TypedDocumentString(`
    query SelectListDisplayAreasToPick($warehouseId: ID!, $displayPicksFiltersInputModel: DisplayPicksFiltersInput!) {
  mobile {
    displayAreasToPick(
      warehouseId: $warehouseId
      displayPicksFiltersInputModel: $displayPicksFiltersInputModel
    ) {
      equipment {
        id
        name
      }
      aisle
      quantity
      otherCartInAisle
    }
  }
}
    `) as unknown as TypedDocumentString<SelectListDisplayAreasToPickQuery, SelectListDisplayAreasToPickQueryVariables>;
export const SelectListFiltersDocument = new TypedDocumentString(`
    query SelectListFilters {
  salesChannels {
    items {
      id
      name
    }
  }
}
    `) as unknown as TypedDocumentString<SelectListFiltersQuery, SelectListFiltersQueryVariables>;
export const GetProductPricesProductPricesDocument = new TypedDocumentString(`
    query GetProductPricesProductPrices($id: ID!) {
  product(id: $id) {
    id
    type
    sku
    unitOfMeasureId
    active
    description
    dropShipMode
    salesChannelPrices {
      salesChannelId
      price
      stockOnHand
      dropShipPrice
      minPrice
      minDropShipPrice
    }
  }
}
    `) as unknown as TypedDocumentString<GetProductPricesProductPricesQuery, GetProductPricesProductPricesQueryVariables>;
export const SaveProductPricesProductPricesDocument = new TypedDocumentString(`
    mutation SaveProductPricesProductPrices($original: ProductInput!, $modified: ProductInput!) {
  product {
    edit(original: $original, modified: $modified) {
      id
      active
      description
      dropShipMode
      sku
      type
      unitOfMeasureId
      salesChannelPrices {
        price
        salesChannelId
        stockOnHand
        dropShipPrice
        minPrice
        minDropShipPrice
      }
    }
  }
}
    `) as unknown as TypedDocumentString<SaveProductPricesProductPricesMutation, SaveProductPricesProductPricesMutationVariables>;
export const GetSalesChannelsProductPricesDocument = new TypedDocumentString(`
    query GetSalesChannelsProductPrices {
  salesChannels {
    items {
      id
      name
      active
    }
  }
}
    `) as unknown as TypedDocumentString<GetSalesChannelsProductPricesQuery, GetSalesChannelsProductPricesQueryVariables>;