import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { useState } from "react";
import { /*Button,*/ Card, Col, Form, /*Modal,*/ Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VArray, VButton, VControl, VForm, VGroup, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import ProductCondition from "../../../enums/ProductCondition";
import ReturnFault from "../../../enums/ReturnFault";
//import ReturnTo from "../../../enums/ReturnTo";
import StateCode from "../../../enums/StatesCode";
//import Rounding from "../../../helpers/Rounding";
import FormatDate from "../../../helpers/FormatDate";
import ProductType from "../../../enums/ProductType";
import ReturnStatus from "../../../enums/ReturnStatus";
import ReturnTo from "../../../enums/ReturnTo";
import PackageType from "../../../enums/PackageType";
import PackingType from "../../../enums/PackingType";
import StringHelper from "../../../helpers/StringHelper";

const returnQueryBase = `
id
returnReason {
  id
  description
}
atFault
status
internalNotes
createdAt
refundedAt
attemptedMachineMake
attemptedMachineModel
packageType
packingType
conditionDetails
totalRefund
returnSalesChannelReference
lineItems {
  id
  quantityReship
  quantityReturn
  product {
    id
    sku
    type
    partNumbers {
      partNumber
    }
    description
  }
  condition
  isAdditionalCharge
  refundUnitPrice
  refundTax
  refundTotal
  returnPurchaseOrderLineItem {
    id
    product {
      id
      sku
      description
      type
    }
  }
}
originalSale {
  id
  salesChannel {
    name
  }
  customerReference
  salesChannelReference
  subtotal
  tax
  orderDate
  total
  billingAddress {
    id
    firstName
    lastName
    company
    address1
    address2
    city
    state
    zip
    phone
    country {
      id
      isoCode
    }
  }
  shippingAddress {
    id
    firstName
    lastName
    company
    address1
    address2
    city
    state
    zip
    phone
    country {
      id
      isoCode
    }
  }
}
reshipSale {
  attachments {
    id
  }
  id
  lineItems {
    id
    product {
      id
      sku
      description
      type
    }
    quantityShipped
  }
}
returnPurchaseOrder {
  attachments {
    id
  }
  id
  billingAddress {
      id
      firstName
      lastName
      company
      address1
      address2
      city
      state
      zip
      phone
      country {
        id
        isoCode
      }
    }
    shippingAddress {
      id
      firstName
      lastName
      company
      address1
      address2
      city
      state
      zip
      phone
      country {
        id
        isoCode
      }
    }
  invoices {
    id
    lineItems {
      purchaseOrderLineItemId
      id
      actualCondition
      expectedQuantity
      actualQuantity
    }
    trackingNumbers {
      purchaseInvoiceId
      trackingNumber
      sortOrder
      shippingServiceId
    }
  }
}
`;

//#region Queries and Interfaces
const returnQuery = `
query ($id: ID!) {
    return(id: $id) {
        ${returnQueryBase}
    }
  }
`;

interface IReturnQueryVariables {
    id: string;
}

interface IReturnQueryResult {
    return: IReturn;
}

interface IReturn {
    id: string;
    status: ReturnStatus;
    returnReason: {
        id: string;
        description: string;
    };
    createdAt: string | null;
    refundedAt: string | null;
    atFault: ReturnFault;
    attemptedMachineMake: string | null;
    attemptedMachineModel: string | null;
    conditionDetails: string | null;
    packageType: PackageType;
    packingType: PackingType;
    internalNotes: string | null;
    totalRefund: number | null;
    returnSalesChannelReference: string | null;
    lineItems: Array<IReturnLineItem>;
    originalSale: {
        id: string;
        salesChannel: {
            name: string;
        } | null;
        customerReference: string | null;
        salesChannelReference: string | null;
        subtotal: number;
        tax: number;
        orderDate: string | null;
        total: number;
        billingAddress: IAddress | null;
        shippingAddress: IAddress | null;
    };
    reshipSale: {
        id: string;
        attachments: Array<IAttachment>;
        lineItems: Array<{
            id: string;
            product: {
                id: string;
                sku: string;
                description: string;
                type: ProductType;
            };
            quantityShipped: number;
        }>;
    } | null;
    returnPurchaseOrder: {
        id: string;
        attachments: Array<IAttachment>;
        billingAddress: IAddress | null;
        shippingAddress: IAddress | null;
        invoices: Array<{
            id: string;
            lineItems: Array<{
                purchaseOrderLineItemId: string;
                id: string;
                actualCondition: ProductCondition | null;
                expectedQuantity: number;
                actualQuantity: number;
            }>;
            trackingNumbers: Array<{
                purchaseInvoiceId: string;
                trackingNumber: string;
                sortOrder: number;
                shippingServiceId: string;
            }>;
        }>;
    } | null;
}

interface IAttachment {
    id: string;
}

interface IReturnLineItem {
    id: string;
    quantityReship: number;
    quantityReturn: number;
    product: {
        id: string;
        sku: string;
        type: ProductType;
        partNumbers: Array<{
            partNumber: string;
        }>;
        description: string;
    };
    condition: ProductCondition;
    isAdditionalCharge: boolean;
    refundUnitPrice: number;
    refundTax: number;
    refundTotal: number;
    returnPurchaseOrderLineItem: {
        id: string;
        product: {
            id: string;
            sku: string;
            description: string;
            type: ProductType;
        } | null;
    } | null;
}

// interface IPurchaseInvoice {
//     trackingNumbers: Array<{
//         purchaseInvoiceId: string;
//         trackingNumber: string;
//         sortOrder: number;
//     }>;
//     id: string;
//     lineItems: Array<{
//         id: string;
//         purchaseOrderLineItem: {
//             id: string;
//             description: string;
//             product: {
//                 id: string;
//                 sku: string;
//             } | null;
//             unitPrice: number;
//             total: number;
//             expectedCondition: ProductCondition | null;
//             isAdditionalCharge: boolean;
//             quantity: number;
//             unitOfMeasureId: string;
//             discount: number;
//             sortOrder: number;
//             comment: string | null;
//             partNumber: string | null;
//             productId: string | null;
//             returnOriginalUnitPrice: number | null;
//             supplierSku: string | null;
//         };
//         unitPrice: number;
//         total: number;
//         actualCondition: ProductCondition | null;
//     }>;
// }

const countryQuery = `
{
  countries {
    items {
      id
      name
      isoCode
      sortOrder
    }
  }
}
`;

interface ICountryQueryResult {
    countries: {
        items: Array<ICountry>;
    };
}

interface ICountry {
    id: string;
    name: string;
    isoCode: string;
    sortOrder: number;
}

// const returnReasonQuery = `
// {
//     returnReasons {
//       items {
//         id
//         description
//       }
//     }
//   }
// `;

// interface IReturnReasonQueryResult {
//     returnReasons: {
//         items: Array<{
//             id: string;
//             description: string;
//         }>;
//     };
// }

const editReturnMutation = `
mutation ($original: ReturnInput!, $modified: ReturnInput!) {
    return {
      edit(original: $original, modified: $modified) {
        ${returnQueryBase}
      }
    }
  }  
`;

interface IReturnInputModel {
    id: string;
    originalSaleId: string;
    status: string;
    totalRefund: number | null;
    returnReasonId: string;
    atFault: string;
    internalNotes: string | null;
    lineItems: IReturnLineItemInputModel[];
    attemptedMachineMake: string | null;
    attemptedMachineModel: string | null;
    conditionDetails: string | null;
    packageType: string | null;
    packingType: string | null;
    lastShipmentMoveDate: string | null;
    returnSalesChannelReference: string | null;
}

interface IReturnLineItemInputModel {
    /**The id of the return line item */
    id: string;
    /**The original SaleLineItemId of this item */
    originalSaleLineItemId: string;
    /* the reship sale line item id */
    reshipSaleLineItemId: string | null;
    /* the return purchase order line item id */
    returnPurchaseOrderLineItemId: string | null;
    /**Id of the product. */
    productId: string;
    /**The sku of the product. Only needs to be provided if the product id is not known.*/
    sku: string | null;
    //The number of products the customer wants to be reshipped.
    quantityReship: number;
    /**The number of products the customer is requesting to return. */
    quantityReturn: number;
    /**Where the item should be returned to */
    returnTo: ReturnTo;
    /**The expected condition of the product(s) that is being returned. */
    condition: string;
    /**Unit of measure id */
    unitOfMeasureId: string;
    //Refund expected?
    refundUnitPrice: boolean;
    //The amount of tax the customer will get refunded for this item.
    refundTax: number;
    /**The total amount of money the customer will get refunded for this item. */
    refundTotaL: number;
    /* sort order */
    sortOrder: number;
    /* Is additional charge */
    isAdditionalCharge: boolean;
}

interface IEditReturnMutationVariables {
    original: IReturnInputModel;
    modified: IReturnInputModel;
}

interface IEditReturnMutationResult {
    return: {
        edit: IReturn;
    };
}

interface IEditReturnLineItemClient {
    productSku: string;
    price: string;
    tax: string;
    total: string;
    expectedCondition: string;
    actualCondition: string;
    quantity: string;
    description: string;
    returnTo: string;
    refundTotal: string;
}

interface IEditReturnClient {
    returnReasonId: string;
    customerReference: string | null;
    createdAt: string | null;
    refundedAt: string | null;
    returnFault: string;
    returnSalesChannelReference: string | null;
    internalNotes: string | null;
    shippingFirstName: string | null;
    shippingLastName: string | null;
    shippingCompany: string | null;
    shippingAddress1: string | null;
    shippingAddress2: string | null;
    shippingCity: string | null;
    shippingState: string | null;
    shippingZip: string | null;
    shippingPhone: string | null;
    shippingCountryId: string | null;
    billingFirstName: string | null;
    billingLastName: string | null;
    billingCompany: string | null;
    billingAddress1: string | null;
    billingAddress2: string | null;
    billingCity: string | null;
    billingState: string | null;
    billingZip: string | null;
    billingPhone: string | null;
    billingCountryId: string | null;
    attemptedMachineMake: string | null;
    attemptedMachineModel: string | null;
    defectType: string | null;
    packageType: string | null;
    packingType: string | null;
    lineItems: Array<IEditReturnLineItemClient>;
    //additionalChargesLineItems: Array<IEditReturnLineItemClient>;
    status: string;
}

interface IAddress {
    id: string;
    description: string | null;
    firstName: string | null;
    lastName: string | null;
    company: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    countryId: string | null;
    phone: string | null;
}

// const purchaseInvoiceQueryBase = `
// trackingNumbers {
//     purchaseInvoiceId
//     trackingNumber
//     sortOrder
// }
// id
// lineItems {
//   id
//   purchaseOrderLineItem {
//     id
//     description
//     product {
//       id
//       sku
//     }
//     unitPrice
//     total
//     expectedCondition
//     isAdditionalCharge
//     quantity
//     unitOfMeasureId
//     discount
//     sortOrder
//     comment
//     partNumber
//     productId
//     returnOriginalUnitPrice
//     supplierSku
//   }
//   unitPrice
//   total
//   actualCondition
// }
// `;

// const addTrackingNumberMutation = `
// mutation(
//     $purchaseInvoiceId: ID!,
//     $trackingNumber: String!,
//     $shippingServiceId: ID!,
//     $shippingLabelCost: Decimal!
//   ) {
//     purchaseInvoice {
//       addReturnTrackingNumber(
//         purchaseInvoiceId: $purchaseInvoiceId,
//         trackingNumber: $trackingNumber,
//         shippingServiceId: $shippingServiceId,
//         shippingLabelCost: $shippingLabelCost
//       )
//     }
//     query {
//       purchaseInvoice(id: $purchaseInvoiceId) {
//         ${purchaseInvoiceQueryBase}
//       }
//     }
//   }
// `;

// interface IAddTrackingNumberMutationVariables {
//     purchaseInvoiceId: string;
//     trackingNumber: string;
//     shippingServiceId: string;
//     shippingLabelCost: number;
// }

// interface IAddTrackingNumberMutationResult {
//     purchaseInvoice: {
//         addReturnTrackingNumber: boolean;
//     };
//     query: {
//         purchaseInvoice: IPurchaseInvoice;
//     };
// }

// const shippingServiceQuery = `
// {
//   shippingServices {
//     items {
//       id
//       shippingCarrier {
//         id
//         name
//       }
//       name
//     }
//   }
// }
// `;

// interface IShippingServiceQueryResult {
//     shippingServices: {
//         items: Array<{
//             id: string;
//             shippingCarrier: {
//                 id: string;
//                 name: string;
//             };
//             name: string;
//         }>;
//     };
// }

interface IRouteParams {
    id: string;
}
//#endregion

const ReturnEdit = (setReturnPageHeaderStatus: React.Dispatch<React.SetStateAction<ReturnStatus | undefined>>) => {
    const params = useParams<IRouteParams>();
    const [saveReturnLoading, setSaveReturnLoading] = useState(false);
    //const [showAddTrackingNumberModal, setShowAddTrackingNumberModal] = useState(false);

    const {
        data: countryData,
        error: countryError,
        refetch: countryRefetch,
        loading: countryLoading,
    } = useQuery<ICountryQueryResult, {}>(countryQuery, { fetchPolicy: "no-cache" });

    // const {
    //     loading: returnReasonLoading,
    //     refetch: returnReasonRefetch,
    //     data: returnReasonData,
    //     error: returnReasonError,
    // } = useQuery<IReturnReasonQueryResult>(returnReasonQuery, { fetchPolicy: "no-cache" });

    const {
        data: returnData,
        error: returnError,
        refetch: returnRefetch,
        loading: returnLoading,
    } = useQuery<IReturnQueryResult, IReturnQueryVariables>(returnQuery, {
        variables: { id: params.id },
        fetchPolicy: "no-cache",
    });

    // const {
    //     loading: shippingServiceLoading,
    //     refetch: shippingServiceRefetch,
    //     data: shippingServiceData,
    //     error: shippingServiceError,
    // } = useQuery<IShippingServiceQueryResult>(shippingServiceQuery, {
    //     fetchPolicy: "no-cache",
    // });

    const [runEditReturnMutation] = useMutation<IEditReturnMutationResult, IEditReturnMutationVariables>(editReturnMutation);

    // const [addTrackingNumberLoading, setAddTrackingNumberLoading] = useState(false);
    // const [runAddTrackingNumberMutation] = useMutation<IAddTrackingNumberMutationResult, IAddTrackingNumberMutationVariables>(
    //     addTrackingNumberMutation
    // );

    if (returnError) return <ErrorDisplay onClick={returnRefetch}>{returnError.message}</ErrorDisplay>;
    if (countryError) return <ErrorDisplay onClick={countryRefetch}>{countryError.message}</ErrorDisplay>;
    //if (returnReasonError) return <ErrorDisplay onClick={returnReasonRefetch}>{returnReasonError.message}</ErrorDisplay>;
    //if (shippingServiceError) return <ErrorDisplay onClick={shippingServiceRefetch}>{shippingServiceError.message}</ErrorDisplay>;

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const initialValue: IEditReturnClient = {
        customerReference: returnData?.return.originalSale?.customerReference ?? "",
        createdAt: returnData?.return?.createdAt ?? "",
        internalNotes: returnData?.return?.internalNotes ?? "",
        returnFault: returnData?.return?.atFault ?? "",
        returnReasonId: returnData?.return?.returnReason?.id ?? "",
        refundedAt: returnData?.return?.refundedAt ?? "",
        attemptedMachineMake: returnData?.return?.attemptedMachineMake ?? "",
        attemptedMachineModel: returnData?.return?.attemptedMachineModel ?? "",
        defectType: returnData?.return?.conditionDetails ?? "",
        packageType: returnData?.return?.packageType ?? "",
        packingType: returnData?.return?.packingType ?? "",
        returnSalesChannelReference: returnData?.return?.returnSalesChannelReference ?? "",
        shippingAddress1: returnData?.return?.returnPurchaseOrder?.shippingAddress?.address1 ?? "",
        shippingAddress2: returnData?.return?.returnPurchaseOrder?.shippingAddress?.address2 ?? "",
        shippingCity: returnData?.return?.returnPurchaseOrder?.shippingAddress?.city ?? "",
        shippingCompany: returnData?.return?.returnPurchaseOrder?.shippingAddress?.company ?? "",
        shippingCountryId: returnData?.return?.returnPurchaseOrder?.shippingAddress?.countryId ?? "",
        shippingFirstName: returnData?.return?.returnPurchaseOrder?.shippingAddress?.firstName ?? "",
        shippingLastName: returnData?.return?.returnPurchaseOrder?.shippingAddress?.lastName ?? "",
        shippingPhone: returnData?.return?.returnPurchaseOrder?.shippingAddress?.phone ?? "",
        shippingState: returnData?.return?.returnPurchaseOrder?.shippingAddress?.state ?? "",
        shippingZip: returnData?.return?.returnPurchaseOrder?.shippingAddress?.zip ?? "",
        billingAddress1: returnData?.return?.returnPurchaseOrder?.billingAddress?.address1 ?? "",
        billingAddress2: returnData?.return?.returnPurchaseOrder?.billingAddress?.address2 ?? "",
        billingCity: returnData?.return?.returnPurchaseOrder?.billingAddress?.city ?? "",
        billingCompany: returnData?.return?.returnPurchaseOrder?.billingAddress?.company ?? "",
        billingCountryId: returnData?.return?.returnPurchaseOrder?.billingAddress?.countryId ?? "",
        billingFirstName: returnData?.return?.returnPurchaseOrder?.billingAddress?.firstName ?? "",
        billingLastName: returnData?.return?.returnPurchaseOrder?.billingAddress?.lastName ?? "",
        billingPhone: returnData?.return?.returnPurchaseOrder?.billingAddress?.phone ?? "",
        billingState: returnData?.return?.returnPurchaseOrder?.billingAddress?.state ?? "",
        billingZip: returnData?.return?.returnPurchaseOrder?.billingAddress?.zip ?? "",
        lineItems: (returnData?.return?.lineItems ?? [])
            //.filter((x) => !x.returnLineItem.isAdditionalCharge)
            .map((returnLineItem) => {
                return {
                    expectedCondition: returnLineItem.condition,
                    actualCondition: returnLineItem.condition,
                    description: returnLineItem.returnPurchaseOrderLineItem?.product?.description ?? "",
                    price: returnLineItem.refundUnitPrice.toString(),
                    productSku: returnLineItem.returnPurchaseOrderLineItem?.product?.sku ?? returnLineItem.product?.sku ?? "",
                    quantity: returnLineItem.quantityReturn.toString(),
                    refundTotal: formatter.format(returnLineItem.refundTotal),
                    returnTo: ReturnTo.Zbox,
                    tax: formatter.format(returnLineItem.refundTax),
                    total: formatter.format(returnLineItem.refundTotal),
                    // returnPurchaseOrderLineItemId: returnLineItem.returnPurchaseOrderLineItem?.id,
                    // productSku: returnLineItem.returnPurchaseOrderLineItem?.product?.sku ?? "",
                    // customerReference: "",
                    // price: returnLineItem.refundUnitPrice.toString(),
                    // tax: formatter.format(returnLineItem.refundTax),
                    // total: formatter.format(returnLineItem.refundTotal),
                    // expectedCondition: returnLineItem.condition ?? "",
                    // //todo: need to grab invoice line item
                    // actualCondition: returnLineItem.condition ?? "",
                    // quantity: returnLineItem.quantityReturn.toString(),
                };
            }),
        // additionalChargesLineItems: (returnData?.return?.lineItems ?? [])
        //     //.filter((x) => x.purchaseOrderLineItem.isAdditionalCharge)
        //     .map((returnLineItem) => {
        //         return {
        //             actualCondition: returnLineItem.condition,
        //             description: returnLineItem.returnPurchaseOrderLineItem?.product?.description ?? "",
        //             expectedCondition: returnLineItem.condition,
        //             price: returnLineItem.refundUnitPrice.toString(),
        //             productSku: returnLineItem.returnPurchaseOrderLineItem?.product?.sku ?? "",
        //             quantity: returnLineItem.quantityReturn.toString(),
        //             refundTotal: formatter.format(returnLineItem.refundTotal),
        //             returnTo: ReturnTo.Zbox,
        //             tax: formatter.format(0),
        //             total: formatter.format(returnLineItem.refundTotal),
        //             // purchaseOrderLineItemId: returnLineItem.returnPurchaseOrderLineItem?.id,
        //             // productSku: returnLineItem.returnPurchaseOrderLineItem?.product?.sku ?? "",
        //             // customerReference: "",
        //             // price: returnLineItem.refundUnitPrice.toString(),
        //             // tax: formatter.format(0),
        //             // total: formatter.format(returnLineItem.refundTotal),
        //             // expectedCondition: returnLineItem.condition ?? "",
        //             // actualCondition: returnLineItem.condition ?? "",
        //             // quantity: returnLineItem.quantityReturn.toString(),
        //         };
        //     }),

        status: returnData?.return?.status ?? "",
    };

    const onSaveChanges = (editedInitialValue: IEditReturnClient) => {
        if (loading) return;
        if (!returnData?.return) return alert(`Return data is null`);
        setSaveReturnLoading(true);
        //#region Commented out code
        // const purchaseOrderLineItemsMap = new Map(
        //     returnData?.return?.returnPurchaseOrder?.invoices?.flatMap((i) => i.lineItems).map((li) => [li.purchaseOrderLineItemId, li])
        // );

        // const editedLineItems = editedInitialValue.lineItems.concat(editedInitialValue.additionalChargesLineItems).map((li) => {
        //     //const lineItemMap = purchaseOrderLineItemsMap.get();
        //     const quantity = +li.quantity;
        //     const unitPrice = +li.price;

        //     if (!(quantity >= 0) || isNaN(quantity))
        //         throw new Error(`Line Item Error: Quantity for purchase order line item id ${li.productSku} is less than zero.`);

        //     if (isNaN(unitPrice) || unitPrice < 0)
        //         throw new Error(`Line Item Error: Unit price for purchase order line item id ${li.productSku} is invalid.`);

        //     return {
        //         expectedCondition: "",
        //         id: "",
        //         productId: "",
        //         quantityReship: 0,
        //         quantityReturn: 0,
        //         refundAmount: 0,
        //         refundExpected: false,
        //         returnTo: ReturnTo.Zbox,
        //         saleLineItemId: "",
        //         sku: "",
        //     } as IReturnLineItemInputModel;
        // });

        //var modifiedSubtotal = +Rounding.EvenRound(editedLineItems.reduce((acc, value) => acc + value.refundAmount, 0)).toFixed(2);
        //#endregion
        try {
            runEditReturnMutation({
                variables: {
                    modified: {
                        id: returnData.return.id,
                        originalSaleId: returnData.return.originalSale.id,
                        status: editedInitialValue.status,
                        returnReasonId: returnData.return.returnReason.id,
                        atFault: returnData.return.atFault,
                        internalNotes: editedInitialValue.internalNotes || null,
                        attemptedMachineMake: editedInitialValue.attemptedMachineMake || null,
                        attemptedMachineModel: editedInitialValue.attemptedMachineModel || null,
                        returnSalesChannelReference: editedInitialValue.returnSalesChannelReference || null,
                        conditionDetails: editedInitialValue.defectType || null,
                        packageType: editedInitialValue.packageType || null,
                        packingType: editedInitialValue.packingType || null,
                        lastShipmentMoveDate: null,
                        lineItems: [],
                        totalRefund: null,
                    },
                    original: {
                        id: returnData.return.id,
                        originalSaleId: returnData.return.originalSale.id,
                        status: returnData.return.status,
                        returnReasonId: returnData.return.returnReason.id,
                        atFault: returnData.return.atFault,
                        internalNotes: returnData.return.internalNotes,
                        attemptedMachineMake: returnData.return.attemptedMachineMake,
                        attemptedMachineModel: returnData.return.attemptedMachineModel,
                        returnSalesChannelReference: returnData.return.returnSalesChannelReference,
                        conditionDetails: returnData.return.conditionDetails,
                        packageType: returnData.return.packageType,
                        packingType: returnData.return.packingType,
                        lastShipmentMoveDate: null,
                        lineItems: [],
                        totalRefund: null,
                    },
                },
            }).then(
                (response) => {
                    returnData.return = response.data.return.edit;
                    setSaveReturnLoading(false);
                    setReturnPageHeaderStatus(response.data?.return.edit?.status ?? undefined);
                },
                (error: GraphQLError) => {
                    setSaveReturnLoading(false);
                    alert(error.message);
                }
            );
        } catch (error) {
            setSaveReturnLoading(false);
            console.log(1);
            alert(error);
        }
    };

    // const addTrackingNumber = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     if (!returnData?.return.returnPurchaseOrder?.invoices?.length) {
    //         alert("No Purchase Invoice found");
    //     } else if (returnData.return.returnPurchaseOrder.invoices.length > 1) {
    //         alert("More than one Purchase Invoice found");
    //     }
    //     //make sure there is only one purchase invoice, as returns will only ever have one.
    //     const purchaseInvoices = returnData?.return.returnPurchaseOrder?.invoices ?? [];
    //     if (!purchaseInvoices.length) return alert("No Purchase Invoice found");
    //     else if (purchaseInvoices.length > 1) return alert("More than one Purchase Invoice found");

    //     const shippingLabelCost = (e.currentTarget.querySelector("#shippingLabelCost") as HTMLInputElement)?.value?.trim();
    //     const trackingNumber = (e.currentTarget.querySelector("#trackingNumber") as HTMLInputElement)?.value?.trim();
    //     const shippingServiceId = (e.currentTarget.querySelector("#shippingServiceId") as HTMLSelectElement)?.value?.trim();

    //     if (shippingLabelCost === "" || isNaN(+shippingLabelCost)) return alert("Shipping Label Cost must be a number");
    //     if (!trackingNumber) return alert("Tracking Number is required");
    //     if (!shippingServiceId) return alert("Shipping Service is required");

    //     try {
    //         setAddTrackingNumberLoading(true);
    //         const response = await runAddTrackingNumberMutation({
    //             variables: {
    //                 purchaseInvoiceId: purchaseInvoices[0].id,
    //                 shippingLabelCost: parseFloat(shippingLabelCost),
    //                 shippingServiceId: shippingServiceId,
    //                 trackingNumber: trackingNumber,
    //             },
    //         });
    //         if (!response.data) return alert("Failed to add tracking number. Response is null.");
    //         returnRefetch();
    //         setShowAddTrackingNumberModal(false);
    //     } catch (error: any) {
    //         alert((error as GraphQLError)?.message ?? "Failed to add tracking number");
    //     } finally {
    //         setAddTrackingNumberLoading(false);
    //     }
    // };

    let loading = false;
    if ([returnLoading, countryLoading /*returnReasonLoading, shippingServiceLoading*/].some((x) => x === true)) {
        loading = true;
        return <Loading />;
    }

    const states = Object.values<string>(StateCode);
    states.unshift("");

    return (
        <>
            <div>
                <VForm onSubmit={onSaveChanges} initialValue={initialValue} saving={saveReturnLoading} key={returnData?.return.id ?? "-"}>
                    <p>
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                    </p>
                    <Row>
                        <Col xxl={8}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    <div>General</div>
                                    <div>
                                        <VSelect fieldName="status">
                                            {Object.values<string>(ReturnStatus).map((s) => (
                                                <option key={s} value={s}>
                                                    {StringHelper.SnakeCaseToTitleCase(s)}
                                                </option>
                                            ))}
                                        </VSelect>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={4}>
                                                    Sales Channel
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        disabled
                                                        readOnly
                                                        value={returnData?.return?.originalSale?.salesChannel?.name ?? ""}
                                                        type="text"
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={4}>
                                                    Sales Channel Reference
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        readOnly
                                                        disabled
                                                        value={returnData?.return?.originalSale?.salesChannelReference ?? ""}
                                                        type="text"
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={4}>
                                                    Creation Date
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        disabled
                                                        readOnly
                                                        value={
                                                            returnData?.return?.createdAt
                                                                ? FormatDate.ConvertDateTimeTo12HrLocalDateTime(
                                                                      returnData?.return?.createdAt
                                                                  )
                                                                : ""
                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={4}>
                                                    Refunded Date
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        disabled
                                                        readOnly
                                                        value={
                                                            returnData?.return?.refundedAt
                                                                ? FormatDate.ConvertDateTimeTo12HrLocalDateTime(
                                                                      returnData?.return?.refundedAt
                                                                  )
                                                                : ""
                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="dueDate">
                                                <VLabel column sm={4}>
                                                    Due Date
                                                </VLabel>
                                                <Col sm={8}>
                                                    <Form.Control disabled readOnly value="# # # # #" type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        {/* <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="">
                                                <VLabel column sm={4}>
                                                    Return Deadline
                                                </VLabel>
                                                <Col sm={8}>
                                                    <Form.Control disabled readOnly value={"# # # # #"} />
                                                </Col>
                                            </VGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="returnReasonId">
                                                <VLabel column sm={4}>
                                                    Return Reason
                                                </VLabel>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        disabled
                                                        readOnly
                                                        value={returnData?.return?.returnReason?.description ?? ""}
                                                    />
                                                    {/* <VSelect disabled readOnly>
                                                        <option></option>
                                                        {(returnReasonData?.returnReasons?.items ?? []).map((r) => (
                                                            <option key={r.id} value={r.id}>
                                                                {r.description}
                                                            </option>
                                                        ))}
                                                    </VSelect> */}
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="atFault">
                                                <VLabel column sm={4}>
                                                    At Fault
                                                </VLabel>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        disabled
                                                        readOnly
                                                        value={StringHelper.SnakeCaseToTitleCase(returnData?.return?.atFault ?? "")}
                                                    />
                                                    {/* <VSelect required>
                                                        <option value=""></option>
                                                        {Object.values<string>(ReturnFault).map((x) => (
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                        ))}
                                                    </VSelect> */}
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="attemptedMachineMake">
                                                <VLabel column sm={4}>
                                                    Att. Machine Make
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="attemptedMachineModel">
                                                <VLabel column sm={4}>
                                                    Att. Machine Model
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="packageType">
                                                <VLabel column sm={4}>
                                                    Package Type
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option value=""></option>
                                                        {Object.values<string>(PackageType).map((x) => (
                                                            <option key={x} value={x}>
                                                                {StringHelper.SnakeCaseToTitleCase(x)}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="packingType">
                                                <VLabel column sm={4}>
                                                    Packing Type
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect>
                                                        <option value=""></option>
                                                        {Object.values<string>(PackingType).map((x) => (
                                                            <option key={x} value={x}>
                                                                {StringHelper.SnakeCaseToTitleCase(x)}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="defectType">
                                                <VLabel column sm={4}>
                                                    Defect Type
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                        <Col lg={6} className="mb-2">
                                            <VGroup as={Row} fieldName="returnSalesChannelReference">
                                                <VLabel column sm={4}>
                                                    Return Reference
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="text" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <VGroup as={Row} fieldName="internalNotes">
                                                <VLabel column sm={4} lg={2}>
                                                    Notes
                                                </VLabel>
                                                <Col sm={8} lg={10}>
                                                    <VControl type="textarea" />
                                                </Col>
                                            </VGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Row>
                                <Col lg={6}>
                                    <Card className="border-primary">
                                        <Card.Header className="bg-primary text-white">Shipping Address</Card.Header>
                                        <Card.Body>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingFirstName">
                                                <VLabel column sm={4}>
                                                    First Name
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingLastName">
                                                <VLabel column sm={4}>
                                                    Last Name
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingCompany">
                                                <VLabel column sm={4}>
                                                    Company
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingAddress1">
                                                <VLabel column sm={4}>
                                                    Address 1
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingAddress2">
                                                <VLabel column sm={4}>
                                                    Address 2
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingCity">
                                                <VLabel column sm={4}>
                                                    City
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingState">
                                                <VLabel column sm={4}>
                                                    State
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect disabled>
                                                        <option></option>
                                                        {states.map((state) => (
                                                            <option key={state} value={state}>
                                                                {state}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingZip">
                                                <VLabel column sm={4}>
                                                    Zip
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingPhone">
                                                <VLabel column sm={4}>
                                                    Phone
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="shippingCountryId">
                                                <VLabel column sm={4}>
                                                    Country
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect disabled>
                                                        <option value=""></option>
                                                        {(countryData?.countries?.items ?? [])
                                                            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                                            .map((country) => (
                                                                <option key={`sales-${country.id}`} value={country.id}>
                                                                    {country.isoCode}
                                                                </option>
                                                            ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className="border-primary">
                                        <Card.Header className="bg-primary text-white">Billing Address</Card.Header>
                                        <Card.Body>
                                            <VGroup as={Row} className="mb-2" fieldName="billingFirstName">
                                                <VLabel column sm={4}>
                                                    First Name
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingLastName">
                                                <VLabel column sm={4}>
                                                    Last Name
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingCompany">
                                                <VLabel column sm={4}>
                                                    Company
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingAddress1">
                                                <VLabel column sm={4}>
                                                    Address 1
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingAddress2">
                                                <VLabel column sm={4}>
                                                    Address 2
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingCity">
                                                <VLabel column sm={4}>
                                                    City
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingState">
                                                <VLabel column sm={4}>
                                                    State
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect disabled>
                                                        <option></option>
                                                        {states.map((state) => (
                                                            <option key={state} value={state}>
                                                                {state}
                                                            </option>
                                                        ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingZip">
                                                <VLabel column sm={4}>
                                                    Zip
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingPhone">
                                                <VLabel column sm={4}>
                                                    Phone
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl disabled type="text" />
                                                </Col>
                                            </VGroup>
                                            <VGroup as={Row} className="mb-2" fieldName="billingCountryId">
                                                <VLabel column sm={4}>
                                                    Country
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VSelect disabled>
                                                        <option value=""></option>
                                                        {(countryData?.countries?.items ?? [])
                                                            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                                            .map((country) => (
                                                                <option key={`sales-${country.id}`} value={country.id}>
                                                                    {country.isoCode}
                                                                </option>
                                                            ))}
                                                    </VSelect>
                                                </Col>
                                            </VGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={4}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">General</Card.Header>
                                <Card.Body>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Subtotal</td>
                                                <td>{formatter.format(returnData?.return?.totalRefund ?? 0)}</td>
                                            </tr>
                                            <tr>
                                                <td>Tax</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Total</b>
                                                </td>
                                                <td>{formatter.format(returnData?.return?.totalRefund ?? 0)}</td>
                                            </tr>
                                            {/* <tr>
                                                <td>Paid</td>
                                                <td>
                                                    {formatter.format(
                                                        (returnData?.return?.originalSale?.paidSubtotal ?? 0) +
                                                            (returnData?.return?.originalSale?.paidTax ?? 0)
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Balance Due</td>
                                                <td>
                                                    {formatter.format(
                                                        (returnData?.return?.originalSale?.paidSubtotal ?? 0) +
                                                            (returnData?.return?.originalSale?.paidTax ?? 0) -
                                                            (returnData?.return?.originalSale?.total ?? 0)
                                                    )}
                                                </td>
                                            </tr> */}
                                            <tr>
                                                <td>
                                                    <b>Amount Refunded</b>
                                                </td>
                                                <td>{formatter.format(returnData?.return?.totalRefund ?? 0)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <Button
                                variant="primary"
                                disabled={
                                    !!returnData?.return?.returnPurchaseOrder?.invoices?.flatMap((invoice) => invoice.trackingNumbers ?? [])
                                        ?.length || loading
                                }
                                onClick={() => setShowAddTrackingNumberModal(true)}
                                className="mb-3"
                            >
                                Add Tracking Number
                            </Button> */}

                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    Products & Services
                                </Card.Header>
                                <Card.Body>
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Product Sku</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Tax</th>
                                                <th>Total</th>
                                                <th>Condition</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VArray fieldName="lineItems">
                                                <tr>
                                                    <td>
                                                        <VControl disabled readOnly type="text" fieldName="productSku" required />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantity" disabled readOnly />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            fieldName="price"
                                                            pattern="^[0-9]*(\.[0-9]{0,4})?$"
                                                            required
                                                            disabled
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="tax" disabled readOnly />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="total" disabled readOnly />
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="expectedCondition" required disabled>
                                                            <option value=""></option>
                                                            {Object.values<string>(ProductCondition).map((condition) => (
                                                                <option key={condition} value={condition}>
                                                                    {condition}
                                                                </option>
                                                            ))}
                                                        </VSelect>
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table>
                                    {/* <br />
                                    Additional Charges and Services
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Product Sku</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Tax</th>
                                                <th>Total</th>
                                                <th>Condition</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VArray fieldName="additionalChargesLineItems">
                                                <tr>
                                                    <td>
                                                        <VControl disabled type="text" fieldName="productSku" required />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="quantity" />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            fieldName="price"
                                                            pattern="^[0-9]*(\.[0-9]{0,4})?$"
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="tax" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" fieldName="total" disabled />
                                                    </td>
                                                    <td>
                                                        <VSelect fieldName="expectedCondition" required>
                                                            <option value=""></option>
                                                            {Object.values<string>(ProductCondition).map((condition) => (
                                                                <option key={condition} value={condition}>
                                                                    {condition}
                                                                </option>
                                                            ))}
                                                        </VSelect>
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </VForm>
            </div>

            {/* <Modal show={showAddTrackingNumberModal} onHide={() => setShowAddTrackingNumberModal(false)} size={"lg"}>
                <form onSubmit={async (e) => await addTrackingNumber(e)}>
                    <Modal.Header closeButton={!addTrackingNumberLoading}>
                        <Modal.Title>Add Tracking Number</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Shipping Service</Form.Label>
                                <Form.Select id="shippingServiceId">
                                    <option value=""></option>
                                    {(shippingServiceData?.shippingServices.items ?? []).map((shippingService) => (
                                        <option value={shippingService.id} key={`ShippingService-${shippingService.id}`}>{shippingService.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Shipping Label Cost</Form.Label>
                                <Form.Control id="shippingLabelCost" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Tracking Number</Form.Label>
                                <Form.Control id="trackingNumber" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            disabled={addTrackingNumberLoading}
                            variant="secondary"
                            onClick={() => setShowAddTrackingNumberModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button disabled={addTrackingNumberLoading} variant="primary" type="submit">
                            Confirm
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> */}
        </>
    );
};

export default ReturnEdit;
